.games-filter {
  display: flex;
  flex-direction: column;

  &__title {
    font-family: $font-accent;
    font-size: 40px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
  }
  &__group-title {
    padding-bottom: 16px;
    font-size: 14px;
    color: color(text, main);
    text-transform: uppercase;
    @include media-sm {
      font-size: 12px;
    }
  }
  .radio-list {
    .formControlLabel-root {
      display: inline-flex;
      margin-right: 10px;
      float: none;
      clear: none;
    }
    .formControlLabel-label {
      padding-left: 5px;
    }
  }
  &__name {
    &:before,
    &:after {
      content: none;
    }
    input {
      display: flex;
      padding: 10px 0 10px 50px;
      width: 340px;
      height: 48px;
      border-radius: 24px;
      border: none;
      background-color: #2d2d2d;

      &:focus {
        border-radius: 24px;
      }
      @include media-md {
        width: 100%;
      }
    }
    .input-before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      padding: 0 0 0 20px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include media-md {
      flex-direction: column;
      gap: 10px;
    }
    &:first-child {
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    &:nth-child(2) {
      align-items: center;
      margin-top: 13px;
    }
  }
  .game-provider-filter {
    @include media-md {
      width: 100%;
    }
  }
  &__group {
    width: 100%;

    .game-provider-filter,
    .game-groups-filter {
      display: flex;
      gap: 8px;
      overflow: auto;

      .field-root .field-helper {
        display: none;
      }
      &__icon {
        min-width: 80px;

        &:hover {
          :not(.mobile),
          :not(.tablet) {
            &::before {
              @include button-cta;
            }
            > label .checkbox-default + span {
              color: #fff;
            }
          }
        }
        &::before {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          border-radius: 16px;
          color: rgba(254, 190, 16, 1);
          background-color: rgba(255, 255, 255, 0.1);
          transition: $main-transition-time;
        }
        &:has(.checkbox-checked) {
          &::before {
            @include button-cta;
          }
        }
        > label {
          z-index: 1;
          display: flex;
          align-items: center;
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0;
          color: #fff;
          text-transform: none;
        }
      }
      .checkbox-default {
        display: none;

        & + span {
          margin-top: 70px;
          width: 100%;
          text-align: center;
          color: rgba(255, 255, 255, 0.65);
          transition: $main-transition-time;
        }
      }
      .checkbox-checked {
        & + span {
          color: #fff;
        }
      }
    }
  }
  &__providersFilter {
    & > div {
      &:before {
        height: 0;
        display: none;
      }
    }
    @include media-md {
      width: 100%;

      > div {
        width: 100%;
      }
    }
    .select-selectMenu {
      display: flex;
      align-items: center;

      @include media-to-md {
        width: 280px;
      }
      &::after {
        @include svg-icon(custom);
        content: "\004e";
        transition: $main-transition-time;
        transform: translateY(-50%) rotate(90deg);
        right: 20px;
      }
    }
    > div,
    .select-focused {
      &::after {
        height: 0;
      }
    }
  }
}
@import '../GameFeaturesFilter/GameFeaturesFilter';
