.login {
  margin-bottom: 30px;

  .container {
    max-width: 800px;
    margin-top: 15px;
    padding: 30px;
    background: var(--content-background);
  }
  &-form {
    .group-root--buttons {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .field-input .input-root {
      @include media-to-md {
        margin-top: 7px;
      }
      @include media-md {
        margin-top: 3px;
      }
    }
  }
  &__field {
    &--icon {
      position: relative;

      &::before {
        position: absolute;
        top: 7px;
        bottom: 0;
        left: 13px;
        display: flex;
        align-items: center;
        height: 54px;
        font-size: 16px;
        color: #fff;
      }
    }
  }
  &__button {
    @include media-xs {
      min-width: auto;
    }
  }
  &__forgot-password {
    display: flex;
    justify-content: flex-end;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
  }
}
