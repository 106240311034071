.winner-list-widget {
  background: var(--background-color);
  padding: 10px;
  box-shadow: var(--winner-list-widget-box-shadow);
  overflow: hidden;
  &__placeholder {
    opacity: 1;
    transition: all 0.3s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__content {
    text-align: center;
    .swiper-wrapper {
      display: inline-flex;
    }
    .swiper-slide {
      margin: 0 5px;
    }
  }
  &__image {
    position: relative;
    overflow: hidden;
    &--loaded {
      .winner-list-widget__placeholder {
        opacity: 0;
      }
    }
    .image-img {
      width: auto;
      height: 90px;
      filter: blur(1px);
    }
    .image-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #000c;
      font-weight: $font-weight-alt;
    }
  }
  &__amount {
    position: relative;
    font-size: 14px;
    color: color(primary, main);
    padding: 3px;
  }
  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  &__title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
    padding: 3px;
  }
  &__game-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 10px;
    padding: 3px;
  }
  &__game-link {
    &.button-root {
      min-width: auto;
      min-height: auto;
      padding: 0;
      line-height: initial;
      color: color(secondary, main);
      font-size: 10px;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
