%mobileFilter {
  @include media-md {
    filter: brightness(0.3);
  }
}
%comingSoon {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: {
    color: color(background, protection);
    position: right center;
    repeat: no-repeat;
    size: cover;
  }

  @include media-to-md {
    justify-content: center;
    background-image: url("/cms/img/coming-soon/coming-soon.png");
  }
  @include media-md {
    background-image: url("/cms/img/coming-soon/mobile/bg.svg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-size: contain;

    @include media-to-md {
      width: 437px;
      height: 48px;
      background-image: url("/cms/img/coming-soon/24+.svg");
    }
    @include media-md {
      z-index: 2;
      min-width: 226px;
      width: 50%;
      min-height: 64px;
      margin: auto;
      background-position: center bottom;
      background-image: url("/cms/img/coming-soon/mobile/24+.svg");
    }
  }
}
%comingSoon__logo {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 190px;
  height: 32px;
  object-fit: contain;
}
%comingSoon__content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 15px;

  @include media-to-md {
    max-width: 400px;
    margin: auto 10%;
  }
  @include media-md {
    max-width: 350px;
    margin: 120px auto 40px;
    padding: 0 25px;
  }
}
%comingSoon__subTitle {
  margin: 0;
  font-family: $font-primary;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  text-transform: unset;
  color: #FEBE10;

  @include media-to-md {
    text-align: left;
  }
  @include media-md {
    text-align: center;
  }
}
%comingSoon__title {
  margin: 0;
  font-family: $font-accent;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include media-to-md {
    font-size: 48px;
    line-height: 46px;
  }
  @include media-md {
    font-size: 32px;
    line-height: 30px;
    text-align: center;
  }
  strong {
    margin: 0;
    font-family: $font-accent;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FEBE10;

    @include media-to-md {
      font-size: 48px;
      line-height: 46px;
    }
    @include media-md {
      font-size: 32px;
      line-height: 30px;
      text-align: center;
    }
  }
}
%comingSoon__text {
  max-width: 480px;
  margin: 0 auto;
  font-weight: 400;
  font-family: $font-primary;
  font-size: 16px;
  color: #fff;

  @include media-to-md {
    line-height: 145%;
  }
  @include media-md {
    line-height: 26px;
    text-align: center;
  }
}
%comingSoon__hero {
  @include media-md {
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url("/cms/img/coming-soon/mobile/hero/ellipse.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background-image: url("/cms/img/coming-soon/mobile/hero/hero.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center bottom;
    }

    &::before {
      z-index: 1;
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(58, 25, 121, 0) 65.62%, #3A1979 100%);
    }
  }
}
.comingSoon {
  @extend %comingSoon;

  &__logo {
    @extend %comingSoon__logo;
  }
  &__content {
    @extend %comingSoon__content;
  }
  &__subTitle {
    @extend %comingSoon__subTitle;
  }
  &__title {
    @extend %comingSoon__title;

    p {
      @extend %comingSoon__title;
    }
  }
  &__text {
    @extend %comingSoon__text;
  }
  &__hero {
    @extend %comingSoon__hero;
  }
  &__button {
    position: relative;
    padding: 18px 66px 18px 22px;
    cursor: pointer;

    @include media-to-md {
      margin: 20px 128px 0 0;
    }
    @include media-md {
      margin: 20px auto;
    }
    &::after {
      content: "24+";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0;
      text-align: center;
      color: #3A1979;
      background-color: #4C259A33;
    }
    &::before {
      content: "";
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 32px;
      background-image: url("/cms/img/coming-soon/VHKA.png");
      background-repeat: no-repeat;
      background-size: contain;

      @include media-to-md {
        top: 50%;
        right: -30px;
        transform: translate(100%, -50%);
      }
      @include media-md {
        bottom: -100%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
  &__form {
    &__iframe {
      width: 100%;
      height: 100%;
      border: 0;

      @include media-to-md {
        min-height: 820px;
      }
      @include media-md {
        min-height: 100vh;
      }
    }
    .modal-paper {
      overflow: hidden;
      box-shadow: none;
      background: #161616;

      @include media-to-md {
        width: 500px;
      }
    }
    .modal-contentRoot {
      padding: 0;
    }
  }
}
