.footer {
  position: relative;
  background: var(--footer-background);
  color: color(footer, text);

  @include media-to-md {
    margin: 220px auto 0;
  }
  @include media-md {
    margin: 157px auto 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 32px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #383838;
    }
    @include media-to-md {
      justify-content: space-between;
    }
    @include media-md {
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;
    }
  }
  .footer-banner {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border-radius: 8px;
    background-color: #3b1978;
    background-image: url("/cms/img/footer/bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include media-to-md {
      position: relative;
      margin-top: -155px;
      padding: 48px;

      &::before {
        content: "";
        position: absolute;
        right: 80px;
        bottom: 0;
        width: 368px;
        height: 350px;
        background-image: url("/cms/img/footer/girl.png");
        background-position: bottom right;
        background-repeat: no-repeat;
      }
    }
    @include media-md {
      position: relative;
      margin-top: -166px;
    }
    @include media-md {
      padding: 32px;
    }
    &__title {
      display: flex;
      margin: 0 0 16px;
      padding: 0;
      font-family: $font-accent;
      font-weight: 600;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #fff;

      @include media-to-md {
        max-width: 400px;
        font-size: 32px;
        line-height: 30px;
      }
      @include media-md {
        font-size: 24px;
        line-height: 24px;
      }
    }
    &__text {
      display: flex;
      margin: 0 0 32px;
      padding: 0;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0;
      color: #abaeb3;

      @include media-to-md {
        max-width: 525px;
      }
    }
    &__button {
      display: inline-flex;
      border-radius: 24px;
      border: 1px solid rgba(255, 255, 255, 1);
      font-family: $font-accent;
      font-size: 14px;
      font-weight: 600;
      line-height: 13px;
      letter-spacing: 0.04em;
      text-align: center;
    }
  }
  .footer-quickAccessPanel {
    &__links {
      display: flex;
      align-items: center;
      gap: 16px;

      @include media-to-md {
        flex-direction: row;
      }
      @include media-md {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      @include media-md {
        width: 100%;
      }
    }
    &__button {
      @include media-md {
        width: 100%;
      }
    }
    .logo {
      position: relative;
      display: flex;
      align-items: center;

      @include media-to-md {
        gap: 16px;

        &::after {
          content: "";
          display: flex;
          align-items: center;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #757575;
        }
      }
      @include media-md {
        width: 100%;
        margin-bottom: 13px;
      }
      img {
        width: 235px;

        @include media-md {
          margin: auto;
        }
      }
    }
  }
  .footer-gameProviderPanel {
    gap: 15px;

    @include media-md {
      flex-direction: column;
    }
    .footer-licensed-logos {
      width: 170px;
      img {
        max-width: 100%;
      }
    }
  }
  .footer-paymentProviderPanel {
    @include media-md {
      padding-bottom: 14px;
    }
    &__message {
      display: flex;
      align-items: center;
      gap: 16px;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
      text-decoration: none;
    }
    .markdown-root {
      img {
        display: inline-block;
        height: 32px;
        width: 32px;
        margin-right: 8px;
        margin-top: -2px;
        vertical-align: middle;
      }
    }
  }
  .footer-license,
  .footer-version {
    p {
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #737373;
    }
  }
  .footer-bottom {
    background: rgba(22, 22, 22, 1);

    @include media-to-md {
      padding: 12px 0;
    }
  }
  .fpLink {
    text-decoration: none;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}
