.protection-login-form {
  .input-underline {
    background: var(--protection-login-form-input-background);
  }
}
.protection-login {
  &__button {
    width: 100%;
    margin: auto;
  }
}
