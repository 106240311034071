.home-content {
  &--slider {
    .home-content__title {
      margin-top: 15px;
      margin-bottom: 5px;
      padding-right: 90px;
    }
    .game-thumbnail {
      @include media-xs {
        margin: 0;
      }
    }
  }
  @include media-sm {
    &__games {
      order: 2;
    }
    &__filter-wrapper {
      order: 1;
    }
  }
  &__games-count-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__games {
    position: relative;
  }
  &__game-list {
    z-index: 1;
    transition: all 0.3s;
    position: relative;
  }
  &__spinner {
    position: absolute;
    left: 50%;
    opacity: 0;
    top: 0;
    transition: all 0.1s;
    transform: translateX(-50%);
  }
  &__game-group-description {
    margin-top: 30px;
    padding: 30px;
    background: rgba(255, 255, 255, .7);

    @include media(null, $size-md) {
      margin-bottom: -40px;
      padding: 30px 17%;
    }

    h1, h2, h3, h4, h5, h6 {
      text-align: center;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &--loading {
    .home-content__game-list {
      opacity: 0;
      min-height: calc(100vh - #{$header-collapsed-height});
    }
    .home-content__spinner {
      opacity: 1;
      //transition: all 0.3s 0.3s; // TODO: add when game filter smooth scrolling bug will be fixed
    }
  }
}
