.home-new-slider {
  position: relative;
  display: flex;
  flex-direction: column;

  @include media-to-md {
    margin-top: 15px;
    margin-bottom: 40px;
  }
  @include media-md {
    margin-top: 10px;
    margin-bottom: 16px;
    padding: 0 16px;
  }
  &__swiper {
    position: relative;
    width: 100%;

    .swiper-wrapper {
      display: inline-flex;
    }
  }
  &__pagination {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    @include media-to-sm {
      bottom: 58px;
    }
    @include media-sm {
      bottom: 31px;
    }
    .swiper-pagination-bullet {
      position: relative;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 5px;
      border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;

      &-active {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: color(accent, main);
        }
      }
    }
  }
  &__shadow-background {
    z-index: -1;
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 0 0 12px 12px;

    @include media-to-sm {
      top: -110px;
    }
    @include media-sm {
      top: -155px;
    }
    img {
      border-radius: 8px;
      height: 100%;
      object-fit: cover;
      mask-image: linear-gradient(to bottom, rgba(28, 25, 36, 0.2) 0, rgba(28, 25, 36, 0) 100%);
      mask-size: 100% 100%;
      mask-repeat: no-repeat;

      @include media-to-sm {
        width: 100%;
        max-height: 740px;
      }
      @include media-sm {
        max-height: 660px;
      }
    }
  }
  &__title {
    &--accent {
      margin: 0;
      padding: 0;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 700;
      line-height: 160%;
      color: color(accent, main);

      @include media-sm {
        display: none;
      }
    }
    &--main {
      margin: 0;
      padding: 0;
      font-family: $font-accent;
      font-weight: 600;
      line-height: 95%;
      letter-spacing: 1.6px;
      text-transform: uppercase;

      @include media-to-sm {
        font-size: 40px;
      }
      @include media-sm {
        font-size: 18px;
        letter-spacing: 0.72px;
        text-align: center;
      }
    }
  }
  &__description {
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);

    @include media-sm {
      display: none;
    }
  }
  &__info {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;

    @include media-to-sm {
      top: 0;
      left: 0;
      gap: 24px;
      justify-content: space-between;
      max-width: 430px;
      height: 100%;
      padding: 37px;
    }
    @include media-sm {
      left: 50%;
      transform: translateX(-50%);
      gap: 8px;
      align-items: center;
      bottom: 0;
      max-width: 280px;
      width: 100%;
      padding-bottom: 27px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include media-sm {
      justify-content: center;
      width: 100%;
    }
  }
  &__button {
    @include media-to-sm {
      max-width: max-content;
      width: 100%;
      min-width: 196px;
      padding: 13px;
    }
    @include media-sm {
      width: 100%;
      margin-top: 8px;
    }
  }
  &__content {
    position: relative;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
  }
  &__image {
    &--hero {
      z-index: 1;
      position: absolute;
      top: 0;

      @include media-to-md {
        right: 13%;
      }
      @include media-md {
        right: 0;
      }
      @include media-to-sm {
        max-height: 355px;
        height: 100%;
      }
      @include media-sm {
        right: 0;
        bottom: 150px;
        left: 0;
      }
      img {
        @include media-to-sm {
          height: 100%;
        }
        @include media-sm {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    &--background {
      width: 100%;

      &::before {
        content: '';
        z-index: 1;
        position: absolute;
        inset: 0;
        border-radius: 12px;

        @include media-to-sm {
          background: linear-gradient(90deg, rgba(28, 25, 36, 0.60) 25%, rgba(28, 25, 36, 0.00) 75%);
        }
        @include media-sm {
          background: linear-gradient(0deg, rgba(28, 25, 36, 0.60) 44.24%, rgba(28, 25, 36, 0.00) 81.41%), linear-gradient(180deg, rgba(28, 25, 36, 0.00) 18.75%, rgba(28, 25, 36, 0.60) 56.25%);
        }
      }
      img {
        width: 100%;
        border-radius: 12px;
        object-fit: cover;

        @include media-to-sm {
          max-height: 355px;
        }
        @include media-sm {
          max-height: 400px;
        }
      }
    }
  }
  &__information {
    p {
      margin: 0;
      padding: 0;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      line-height: 160%;
      color: #FFF;
    }
  }
}
