@keyframes mobile-app-banner-slide-top {
  0% {
    transform: translate(-50%, 200px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.mobile-app-banner {
  position: fixed;
  width: calc(100% - 40px);
  left: 50%;
  right: 20px;
  bottom: 20px;
  transform: translate(-50%, 0);
  padding: 12px 44px 12px 20px;
  background: #fff;
  border: 1px solid #D3D3D3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  line-height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  overflow: hidden;
  animation: mobile-app-banner-slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  @include media-md {
    bottom: 110px;
  }
  @include media-sm {
    bottom: 65px;
  }

  &_logo {
    display: flex;
  }
  &_text {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 16px;
    flex: auto;
  }
  &_title {
    font-weight: bold;
    font-size: 16px;
  }
  &_description {
    color: #929292;
    font-size: 14px;
  }
  &_button {
    button {
      font-weight: bold;
      font-size: 14px;
      text-transform: none;
      min-width: 0;
      min-height: 0;
      padding: 9px 12px;
    }
  }
  &_close {
    position: absolute;
    right: 8px;
    top: 8px;

    span {
      display: flex;
    }
  }
}
