.game-provider-filter {}
.game-provider-filter-select {
  .select-selectMenu {
    border-color: rgba(255, 255, 255, 0.20);
  }
  &__item {
    padding: 0;
    &--showAll {
      padding: 0px 16px 0px 16px;
      .game-provider-filter-select__item-wrapper {
        border-bottom-width: 1px;
        border-bottom-color: rgba(255, 255, 255, 0.2);
        border-bottom-style: solid;
        padding-bottom: 16px;
      }
      .game-provider-filter-select__item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .game-provider-filter-select__item-label {
        flex: 1 1 auto;
        font-size: 16px;
        font-weight: 600;
      }
      .game-provider-filter-select__item-option {
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 1.6;
        cursor: pointer;
        width: auto;
        border-bottom-width: 0;
      }
      .game-provider-filter-select__search {
        width: 100%;
      }
    }
    &:last-child {
      .game-provider-filter-select__item-option {
        border-bottom-width: 0;
      }
    }
  }
  &__item-option {
    padding: 16px 0;
    margin: 0 16px;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-color: rgba(255, 255, 255, 0.2);
    border-bottom-style: solid;
  }
}

#game-provider-filter-select-menu {
  .select-menuBackdrop {
    @media (max-width: $size-md) {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .select-menuPaper {
    @media (max-width: $size-md) {
      max-width: 100% !important;
      max-height: 40vh!important;
      width: 100% !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      top: unset !important;
      border-radius: 8px 8px 0px 0px;
      border: 1px solid rgba(255, 255, 255, 0.20);
    }
  }
}
