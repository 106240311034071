.game-thumbnail {
  margin: 0 6px 12px;
  background-color: var(--game-thumbnail-background);

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 72%;
  }

  .image-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:hover {
      .game-thumbnail__content,
      .game-thumbnail__rtp,
      .game-thumbnail__name {
        opacity: 1;
      }
      .game-thumbnail__badges,
      .game-thumbnail__last-results,
      .game-thumbnail__jackpot-amount,
      .game-thumbnail__jackpot-badge {
        opacity: 0;
      }
    }

    @include media-xs {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    @at-root {
      .mobile &:hover,
      .tablet &:hover {
        opacity: 0;
      }
    }
  }

  &__placeholder {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &--loaded {
    &:not(.game-thumbnail--portrait) {
      @include custom-border;
    }
    &::after,
    .game-thumbnail__placeholder:not(.game-thumbnail__placeholder_rendering) {
      display: none;
    }
  }
  &__badges {
    position: absolute;
    z-index: 4;
    top: 6px;
    right: 13px;
    left: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 1;
    transition: opacity $main-transition-time;
    pointer-events: none;
    gap: 5px;
    max-width: 100%;
    flex-wrap: wrap;

    &:empty {
      display: none;
    }
  }
  &__badge {
    height: 20px;
    background: {
      color: transparent;
      position: center;
      repeat: no-repeat;
      size: contain;
    }

    &--new {
      width: 70px;
      background-image: url('/cms/img/icons/nieuw-flair.svg');
    }
    &--free-spins {
      width: 99px;
      background-image: url('/cms/img/icons/freespins-flair.svg');
    }
    &--exclusive {
      width: 99px;
      background-image: url('/cms/img/icons/exclusief-flair.svg');
    }

    &--bingo-tag {
      width: 99px;
      margin-left: auto;
      background-image: url('/cms/img/icons/bingo-flair.svg');
    }

    &--drops-tag {
      width: 99px;
      margin-left: auto;
      background-image: url('/cms/img/icons/drops-wins.svg');
    }

    &--freeSpins-tag {
      width: 99px;
      margin-left: auto;
      background-image: url('/cms/img/icons/freespins-flair.svg');
    }

    &--exclusief-tag {
      width: 99px;
      margin-left: auto;
      background-image: url('/cms/img/icons/exclusief-flair.svg');
    }
  }
  &__jackpot-amount {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    padding-left: 28%;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    font-weight: $font-weight-alt;
    text-align: center;
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
    transition: opacity $main-transition-time;
    border: none;
  }
  &__jackpot-badge {
    @include size(53px, 40px);
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 3px;
    background: url('/cms/img/icons/jackpot.svg') transparent center/contain no-repeat;
  }
  /* Fix jumping game images (Safari) (1) */
  > img {
    flex-shrink: 0;
    height: 0; // 1
    width: 100%;
    padding-top: 72%; // 1
    visibility: hidden;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    &[src=""] {
      height: 0; // 1
    }
  }
  &--loaded img {
    height: 100%; // 1
    padding-top: initial; // 1
    visibility: initial;
  }
  &__title,
  &__rtp {
    .desktop .game-slider & {
      @include media-md {
        font-size: 1.75vw;
      }
    }
  }
  &__game-title {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  &__title {
    margin: 0;
    font-family: $font-accent;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: center;
    text-transform: uppercase;
    color: #fff;

    .desktop & {
      @media (min-width: 768px) {
        @include truncate;
      }
    }
  }
  &__subTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
  &__rtp {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    display: inline-flex;
    margin: 0 auto auto 0;
    padding: 3px 10px;
    border-radius: 16px;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #fff;
    background-color: #484947;
    opacity: 0;
    transition: opacity $main-transition-time;

    & > p {
      margin: 0;
    }
  }
  &__name {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    font-size: 14px;
    color: color(text, main);
    opacity: 0;
    transition: opacity $main-transition-time;
    font-weight: 700;
  }
  &__buttons {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__button {
    position: relative;

    &--real,
    &--demo {
      .desktop & {
        @include media-sm {
          display: none;
        }
      }
    }

    &--real {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin-bottom: 15px;
      border-radius: 32px;
      background-color: #00F3C7;

      &::before {
        margin-left: 3px;
        color: #3A1979;
      }
    }
    &--demo {
      font-size: 12px;
      border: 1px solid #fff;

      &:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, .2);
      }
    }
    &--favourite {
      z-index: 3;
      min-width: 0;
      min-height: unset;
      margin: 0;
      padding: 0;
      font-size: 0;
      line-height: 1;
      vertical-align: middle;
      border: 0;
      background: none;

      &::before {
        padding: 0;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.4);
      }
      .button-label {
        display: none;
      }
      &:hover {
        background: none;

        &::before {
          color: color(error);
        }
      }
      .desktop & {
        @include media-sm {
          display: none;
        }
      }
    }
    &--favourite-on {
      &::before {
        @include svg-icon;
        content: '\0058';
        color: color(error);
      }
    }
    .button-label {
      &:empty {
        display: none;
      }
    }
  }
  &__button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    pointer-events: none;

    @media (pointer: coarse) {
      pointer-events: auto;
    }
    .desktop & {
      @include media-sm {
        pointer-events: auto;
      }
    }
  }
  &__content {
    padding: 12px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    border-radius: 8px;
    transition: $main-transition-time;
    background: rgba(0, 0, 0, 0.7);

    &__top {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__last-results {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__last-results-item {
    @include size(20px);
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: $font-weight-alt;
    line-height: 20px;
    text-align: center;

    &:not(:last-of-type) {
      margin-right: 3px;
    }

    &:first-of-type {
      @include size(24px);
      font-size: 12px;
      line-height: 24px;
    }

    &--red {
      background: #ff4747;
    }
    &--black {
      background: #000;
    }
    &--green {
      background: #71ac31;
    }
  }
  &__dealer {
    max-width: 100%;
  }
  &__info {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    transition: opacity $main-transition-time;

    @include media-sm {
      padding: 12px;
    }
  }
  &__info-item {
    width: 100%;
    font-size: 14px;
    padding: 8px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &--name {
      display: none;
    }
    &--seats {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
  &--group--liveRoulette,
  &--group--liveBlackjack,
  &--group--liveShows {
    .game-thumbnail__rtp {
      visibility: hidden;
    }
  }
  &--portrait {
    .game-thumbnail__buttons {
      height: 100%;
    }
  }

  .featured-games-slider & {
    .game-thumbnail__badges,
    .game-thumbnail__jackpot-amount,
    .game-thumbnail__jackpot-badge {
      display: none;
    }

    &__title,
    &__rtp {
      font-size: 20px;
    }
  }

  &__liveCasino {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;

    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &--item {
      display: flex;
      font-weight: 700;
      color: white;
      background-color: rgba(0, 0, 0, 0.6);
      align-items: baseline;
      position: relative;
      padding: 5px;
      gap: 4px;

      &--dealerName {
        display: inline-flex;
        font-size: 12px;
      }

      &--roulette {
        display: flex;
        gap: 6px;
        padding: 3px 5px;

        &:first-child {
          padding-top: 5px;
        }

        &:last-child {
          padding-bottom: 5px;
        }
        &__item {
          line-height: 1;
          min-width: 16px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &--red {
          color: red;
        }
        &--green {
          color: green;
        }
      }

      &--blackjack {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        &--item {
          opacity: 0.5;

          &--active {
            opacity: 1;
          }
          &:before {
            display: inline-block;
            font-family: Glyphter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            content: "E";
          }
        }
      }

      &--person {

        &:before {
          display: inline-block;
          font-family: Glyphter;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          content: "E";
        }
      }
    }

  }
}
