.game-provider-image {
  width: 100%;
  overflow: hidden;

  @include media-to-md {
    max-width: 620px;
  }
  .swiper-root {
    width: 100%;
  }
  .swiper-wrapper {
    display: inline-flex;
  }
  &__image img {
    max-width: 130px;
    width: 100%;
    object-fit: contain;
  }
}
