.aside-menu {
  $indent: 0 23px 0 32px;
  display: grid;
  grid-template-areas: "logo"
                       "payment"
                       "menu";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  gap: 16px;
  max-width: 100vw;
  width: 397px;
  height: 100%;
  padding: 24px 0;
  font-size: 18px;
  overflow-y: auto;
  background: var(--aside-menu-background);

  .mobile &,
  .tablet & {
    @include customVh;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &__logo {
    display: grid;
    grid-area: logo;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 16px;
    padding: $indent;
  }
  &__close {
    @include close-button;
  }
  &__links-wrapper {
    grid-area: menu;
    flex-direction: column;
    grid-column: span 1;
    width: 100%;

    .icon {
      &::before {
        padding: 0 8px 0 0;
        color: #737373;
      }
    }
  }
  &__hot-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-area: payment;
    gap: 16px 8px;
    padding: $indent;
  }
  &__search {
    grid-column: span 2;
    padding: 10px 0 10px 14px;
    background-color: #2d2d2d;

    &:hover {
      opacity: 0.7;
      background-color: #2d2d2d;
    }
    &::before {
      padding-right: 10px;
      font-size: 20px;
      color: #818181;
    }
    &::after {
      @include svg-icon(custom);
      content: "\004e";
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background-color: #424242;
    }
    .button-label {
      display: flex;
      justify-content: left;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      text-transform: capitalize;
      color: #818181;
    }
  }
  a {
    text-decoration: none;
  }
  //TODO: refactor the remaining part
  .details-expanded {
    .icon {
      &::after {
        transform: rotate(90deg);
      }
    }
  }
  .detailsSummary-root {
    min-height: 0;
    margin: $indent;
    padding: 0;

    &.icon {
      &::after {
        @include svg-icon(custom);
        content: "\004e";
        color: rgba(255, 255, 255, 0.4);
        transition: $main-transition-time;
      }
    }
    > .detailsSummary-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 3px;
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0;
    }
    .settings-menu {
      width: 100%;
    }
  }
  .detailsSummary-content {
    margin: 0;
    color: color(link, default);
  }
  .settings-menu__item-override {
    &:not(:first-child):not(:last-child) {
      .details-root {
        border-top: 1px solid #2d2d2d;
      }
    }
    & > a {
      margin-left: 22px;
    }
    > a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 0;
      padding: 10px 0;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      color: color(link, default);
      transition: $main-transition-time;
      cursor: pointer;

      &:hover,
      &.active {
        color: color(accent, main);
      }
    }
    &:last-of-type > a {
      padding-bottom: 30px;
    }
    .details-root {
      box-shadow: none;
      background: transparent;

      &.details-expanded {
        background-image: var(--gradient);

        .detailsSummary-content {
          &::after {
            transform: rotateX(180deg)
          }
        }
      }
    }
    &--menuAsideStatic {
      .icon-menuAsideStatic {
        display: none;

        & + div {
          padding-top: 15px;
        }
      }
    }
  }
}
