/* Animation */

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/*------------------------------------*\
          Style Guideline
\*------------------------------------*/

$font-fallback:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
    // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
    // Windows
  "Segoe UI",
    // Android
  "Roboto",
    // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-primary: 'Anuphan', $font-fallback;
$font-accent: 'Clash Display', $font-fallback;
$font-weight-base: 400;
$font-weight-alt: 600;

/* color variable map */
$colors: (
  primary: (
    main: #3B1978,
    light: rgba(255, 255, 255, 0.1), //#D7C2FF
    dark: #1F1631,
  ),
  secondary: (
    main: #212124,
    light: #404040,
    dark: #212121
  ),
  accent: (
    main: #FEBE10,
    light: #E9AA01,
    dark: #C79100,
  ),
  info: #ffcc00,
  error: rgba(243, 0, 44, 0.4),
  errorAccent: #FF7777,
  success: rgba(0, 243, 199, 0.4),
  warning: rgba(254, 190, 16, 0.2),
  expired: #d7b354,
  disable: (
    dark: #A0A0A0,
  ),
  label: (
    filled: #fff,
    unfilled: #fff,
    disabled: #A0A0A0
  ),
  input: (
    text: #a2a2a2,
    background: #0F0F0F,
    disabled: #A0A0A0,
    border: #212124,
    hint: #A0A0A0
  ),
  select: (
    text: #fff,
    background: #0F0F0F
  ),
  modal: (
    title: #fff,
    text: #fff,
    background: #0F0F0F,
    border: #0F0F0F
  ),
  footer: (
    text: #fff,
    link: #fff,
    link-active: #fff,
    background: #0F0F0F
  ),
  turnover-progress: (
    text: #fff,
    progress: #fff,
    background: #0F0F0F
  ),
  content: (
    title: #fff,
    background: #0F0F0F
  ),
  text: (
    main: #fff,
    light: #fff,
    dark: #fff
  ),
  link: (
    default: #ffffff,
    hover: #ffffff,
    active: #ffffff
  ),
  main-menu: (
    link: #FEBE10,
    link-active: #FEBE10,
    background: #0F0F0F,
    background-active: #0F0F0F
  ),
  static-menu: (
    border: #0F0F0F,
    background: #0F0F0F
  ),
  loyalty-statuses: (
    bronze: #6398e6,
    silver: #f9813f,
    gold: #ffcd13,
    platinum: #339999,
  ),
  limit-amount: (
    progress-back: rgba(255, 255, 255, 0.1),
    progress-path: #FEBE10,
    switch-default: #0F0F0F,
    background: transparent,
    color: #fff,
    date-background: #0F0F0F
  ),
  background: (
    header: #1B0742CC,
    body: #161616,
    footer: #222222,
    aside-menu: #0F0F0F,
    home-menu: #0F0F0F,
    protection: #3B1978,
    modal: #161616,
    post-login-menu: #0F0F0F,
    post-login-content: #0F0F0F,
    static-menu: #0F0F0F,
    static-content: #0F0F0F,
    filter: #0F0F0F,
    game-window: #0F0F0F
  ),
  promotions-card: (
    title: #000,
    text: #fff,
    link: #3a405b,
  ),
  table: (
    even-background: #0F0F0F,
  ),
  border: (
    main: var(--color-custom-light),
  )
);

$preloading-width: 120px;
$preloading-height: 4px;
$preloading-time: 5s;

// heights
$header-collapsed-height: 144px;
$header-expanded-height: 96px;

// retrieve color from map. Example: 'color: color(primary, main);', 'background: color(background)'
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant != null) {
    @return map-get(map-get($colors, $color-name), $color-variant);
  }
  @else {
    @return map-get($colors, $color-name);
  }
}

/* Mixins */

@mixin svg-icon($name: 'Glyphter') {
  display: inline-block;
  @if $name == 'custom' {
    font-family: 'Glyphter-custom';
  } @else {
    font-family: 'Glyphter';
  }
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$main-transition-time: .25s;

$size-xl: 1920px;
$size-lg: 1440px;
$size-md: 1024px;
$size-sm: 767px;
$size-xs: 480px;


@mixin media($max, $min: null) {
  @if ($min == null) {
    @media (max-width: $max) {
      @content;
    }
  } @else if($max == null) {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @media (max-width: $max) and (min-width: $min) {
      @content;
    }
  }
}
@mixin media-xl {
  @media (max-width: $size-xl) {
    @content;
  }
}
@mixin media-to-lg {
  @media (min-width: $size-lg + 1px) {
    @content;
  }
}
@mixin media-lg {
  @media (max-width: $size-lg) {
    @content;
  }
}
@mixin media-to-md {
  @media (min-width: $size-md + 1px) {
    @content;
  }
}
@mixin media-md-landscape {
  @media (max-width: $size-md) and (orientation: landscape) {
    @content;
  }
}
@mixin media-md {
  @media (max-width: $size-md) {
    @content;
  }
}
@mixin media-sm-landscape {
  @media (max-width: $size-sm) and (orientation: landscape) {
    @content;
  }
}
@mixin media-to-sm {
  @media (min-width: $size-sm + 1px) {
    @content;
  }
}
@mixin media-sm {
  @media (max-width: $size-sm) {
    @content;
  }
}
@mixin media-xs {
  @media (max-width: $size-xs) {
    @content;
  }
}

// Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
@mixin customVh($vh: 100) {
  height: calc(var(--vh, 1vh) * #{$vh});
  will-change: height;
}

// Browsers check hacks
// Microsoft Edge
@mixin isMicrosoftEdge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

// Mozilla Firefox
@mixin isMozillaFirefox {
  @-moz-document url-prefix() {
    @content;
  }
}

// WebP background images with fallback
@mixin webp-bg-img($webp, $fallback) {
  background-image: url($webp);

  html.no-webp & {
    background-image: url($fallback);
  }
}

// Close button
@mixin close-button {
  z-index: 11;
  @include size(48px);
  position: relative;
  min-width: 48px;
  min-height: 48px;
  padding: 0;
  flex: 0 0 auto;
  border: 0;
  box-shadow: none;
  border-radius: 24px;
  transition: all 0.25s;
  color: #fff;
  background-color: #2d2d2d;
  backface-visibility: hidden;
  cursor: pointer;

  &::before {
    @include svg-icon;
    content: '\004f';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: color(text, main);
    font-size: 14px;
    transition: all $main-transition-time;
  }

  &:hover {
    &::before {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
}

.main-container {
  max-width: $size-xl;
  margin-right: auto;
  margin-left: auto;
}

:root {
  --color-blue: #00F3C7;
  --color-pink: #D7C2FF;
  --color-indigo: #3a1979;
  --color-black: #0F0F0F;
  --color-custom-dark: #110c1a;
  --color-custom-light: #1f022e;

  --body-background: #{color(background, body)};
  --body-text-dark-color: #{color(text, main)};

  --content-title-color: #{color(content, title)};
  --content-background: #{color(content, background)};

  --home-menu-background: #{color(background, home-menu)};
  --home-menu-item-color: #302f30;
  --home-menu-icon-color: #2f2f2f;
  --home-menu-swiper-from: rgba(255, 255, 255, 1);
  --home-menu-swiper-to: rgba(255, 255, 255, 0);

  --header-background: #{color(background, header)};
  --header-color: #{color(primary, dark)};
  --header-border: 1px solid #{lighten(color(primary, dark), 60%)};
  --header-box-shadow: none;

  --aside-menu-background: #{color(background, aside-menu)};
  --aside-menu-link-color: #{color(main-menu, link)};
  --aside-menu-border-color: #ccc;

  --close-button-color: #{color(primary, dark)};

  --button-secondary-color: #{color(primary, dark)};
  --button-secondary-hover-color: #{color(primary, light)};
  --button-default-color: #{color(primary, dark)};

  --post-login-content-background: #{color(background, post-login-content)};
  --post-login-menu-wrap-background: #{color(background, post-login-menu)};
  --post-login-settings-menu-background: #f8f8f8;
  --post-login-settings-menu-border-color: #e8e8e8;
  --post-login-menu-link-color: #{color(primary, dark)};

  --footer-background: #{color(background, footer)};
  --footer-top-section-background: rgba(0, 0, 0, 0.10);
  --footer-link-hover-color: #{color(footer, link-active)};

  --primary-light-color: #{color(primary, light)};

  --modal-background: #{color(background, modal)};
  --modal-title-color: #{color(modal, title)};
  --modal-text-color: #{color(modal, text)};

  --static-page-background: #{color(background, static-content)};
  --static-menu-background: #{color(background, static-menu)};
  --static-item-color: rgba(255, 255, 255, 0.6);

  --select-icon-color: #{color(select, text)};
  --select-menu-background: #{color(select, background)};
  --select-menu-item-color: #{color(select, text)};

  --input-color :#{color(input, text)};
  --input-label-unfilled-color: #{color(label, unfilled)};
  --input-label-filled-color: #{color(label, filled)};

  --form-secondary-background: #f2f2f2;

  --table-even-background: #{color(table, even-background)};
  --paginator-background: var(--body-background);

  --notification-background: #161616;
  --notification-color: #{color(text, main)};

  --limit-amount-color: #fff;
  --limit-amount-second-color: #{color(primary, dark)};
  --limit-amount-link-color: #{color(modal, text)};
  --limit-amount-background: #{color(limit-amount, background)};

  --limit-modal-color: #{color(modal, border)};

  --transactions-bets-background: #{color(primary, light)};

  --game-thumbnail-background: var(--body-background);
  --game-slider-nav-background: rgba(255, 255, 255, 0.7);
  --game-slider-nav-icon-color: #{color(secondary, main)};


  --winner-list-widget-box-shadow: inset 0 10px 10px -10px #eee;

  --balance-popover-background: #{color(primary, dark)};
  --balance-popover-border-color: #{color(primary, dark)};
  --balance-popover-border: 1px solid var(--balance-popover-border-color);

  --protection-login-form-input-background: #{color(secondary, main)};
  --protection-adornment-password-background:  #{color(secondary, main)};

  --adornment-password-color: #A0A0A0;

  --input-webkit-autofill-box-shadow: inset 0 0 0 100px #{color(background, modal)};
  --input-webkit-text-fill-color: #{color(input, text)};

  --games-filter-background: #{color(background, filter)};
  --games-filter-text-color: #{color(text, dark)};
  --games-filter-text-checked-color: #{color(text, dark)};

  --gd-background: #{color(background, game-window)};
  --gd-text: #57595b;
  --gd-footer-background: #{color(primary, light)};
  --gd-error-background: #{color(background, game-window)};

  --activity-form-cell-color: #000;
  --activity-form-post-login-title: #fff;

  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
}

@mixin custom-border {
  $border: 1px;

  box-sizing: border-box;
  position: relative;
  border: solid $border transparent;
  border-radius: 8px;
  background: transparent;
  background-clip: padding-box;

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: -$border;
    border-radius: inherit;
    background: radial-gradient(100% 409.52% at 0% 21.62%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 6.77%, rgba(255, 255, 255, 0) 100%);
  }
}
