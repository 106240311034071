.footer-menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  width: 100%;

  @include media-md {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  &__title {
    display: flex;
    margin-bottom: 24px;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
    color: #fff;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 0;
  }
  &__link {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-decoration: none;
    color: #a7a7a7;
  }
  &__item {
    &:last-child {
      @include media-to-md {
        margin-right: 10%;
      }
    }
  }
  li {
    list-style-type: none;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
}
