.document-proof-status {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin-bottom: 15px;

  &::before {
    @include svg-icon;
    content: '\0036';
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 15px;
    font-size: 24px;
    border-radius: 8px 0 0 8px;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  &__wrapper {
    display: flex;
    width: 100%;

    @include media-xs {
      flex-direction: column;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      @include svg-icon;
      content: '\004c';
      margin: 15px;
      font-size: 90px;
      border-radius: 8px 0 0 8px;
      color: rgba(255, 255, 255, 0.2);

      @include media-xs {
        margin: 5px 15px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 15px 15px 15px 0;

    @include media-xs {
      padding-top: 0;
      padding-left: 15px;

      .markdown-root {
        p {
          margin-top: 0;
        }
      }
    }
    .markdown-root,
    .list-root {
      width: 100%;
    }

    .list-root {
      margin-bottom: 20px;
    }

    .button-accent {
      margin-left: -100px;

      @include media-xs {
        margin-left: 0;
      }
    }
  }
  &__rejected {
    &-documents {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 20px;
    }
    &-document {
      text-align: left;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &-document-title {
      font-size: 16px;
      font-weight: $font-weight-alt;

      span {
        font-size: 14px;
        font-weight: $font-weight-base;
        color: color(text, light);
      }
    }
    &-reasons-title {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &-reasons-list {
      margin-top: 5px;
      padding-left: 30px;

      li::first-letter {
        text-transform: capitalize;
      }
    }
  }

  // Type
  &--identity &__icon::before {
    content: '\0028';
  }
  &--address &__icon::before {
    content: '\0078';
  }

  // Status
  &--uploaded {
    border-color: rgba(255, 255, 255, 0.2);

    &::before {
      background: rgba(255, 255, 255, 0.2);
      content: '\0075';
    }
  }
  &--approved {
    border-color: color(success);

    &::before {
      background: color(success);
      content: '\0075';
    }
    .document-proof-status__icon::before {
      color: color(success);
    }
  }

  &--rejected {
    border-color: color(error);

    &::before {
      background: color(error);
      content: '\004f';
    }
    .document-proof-status__icon::before {
      color: color(error);
    }
  }
  &--init {
    border-color: color(accent, main);

    &::before {
      background: color(accent, main);
      content: '\0044';
      font-size: 28px;
      padding: 13px;
      font-weight: $font-weight-alt;
      color: #000;
    }
    .document-proof-status__icon::before {
      color: color(accent, main);
    }
  }

  &--none + &--none,
  &--rejected + &--none {
    border-color: color(input, disabled);

    &::before {
      background: color(input, disabled);
    }
    .document-proof-status__icon::before {
      color: color(input, disabled);
    }
    .document-proof-status__content {
      color: color(input, disabled);
    }
    .button-accent {
      display: none;
    }
  }
}
