.registration {
  .radioGroup-root {
    flex-direction: column;

    label + label {
      span {
        margin-top: 10px;
      }
    }
  }
  &--regular,
  &--null {
    max-width: 720px;
    margin: auto;
    padding: 20px 15px;
  }
  &--cddn {
    @include media-to-md {
      margin: 50px auto;
      padding: 20px 15px;
    }
    @include media-md {
      padding: 15px;
    }
  }
}
