.profile-form {
  .fields-container {
    .gridItem-root {
      &__receiveEmail,
      &__receiveSms,
      &__campaignsEnabled,
      &__publicPerson {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}
.profile-form {
  &__verify {
    position: absolute;
    right: 0;
    height: 100%;
    border-radius: 50%;
    min-height: 26px;
    min-width: 26px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: color(error);

    &:hover {
      background-color: initial;
    }
    & > .button-label:before {
      @include svg-icon;
      content: '\00b1';
    }
  }
}
