  .gd-window__game__poker {
    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }

    .aspectRatio-content {
      position: relative;
    }

    .aspectRatio-container {
      padding-top: 0 !important;
    }

    & > div {
      max-width: 100% !important;
    }
  }
