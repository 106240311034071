/* Menu */

.post-login__menu {
  padding-right: 15px;

  .post-login-menu {
    position: sticky;
    position: -webkit-sticky;

    .icon-menuAsideProfile,
    .icon-menuAsideCampaigns,
    .icon-menuAsideLimits,
    .icon-menuAsideTransactions {
      &::before {
        display: none;
      }
    }
    &_wrap {
      height: 100%;
      padding: 13px 0 0 190px;
    }
    .settings-menu__item-override {
      .details-root {
        background: none;
        box-shadow: none;
        margin-bottom: 24px;

        .detailsSummary-root[role="button"] {
          min-height: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 4px;
        }
        .detailsSummary-root {
          padding: 0;

          &.icon {
            &::after {
              @include svg-icon(custom);
              content: "\004e";
              margin-right: 5px;
              transition: $main-transition-time;
            }
          }
        }
        .detailsSummary-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 11px 18px;
          font-family: $font-primary;
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0;
          color: #fff;
          transition: $main-transition-time;

          &::after {
            transition: $main-transition-time;
          }
        }
        &.details-expanded {
          .icon {
            &::after {
              transform: rotate(90deg);
            }
          }
        }
        .settings-menu {
          display: flex;
          flex-direction: column;
          gap: 14px;
          width: 100%;
          padding-left: 17px;
          background: transparent;

          &__item-override {
            & > a {
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-left: 0;
              padding: 10px 15px;
              font-family: $font-primary;
              font-size: 14px;
              font-weight: 600;
              line-height: 22px;
              letter-spacing: 0;
              text-decoration: none;
              color: rgba(255, 255, 255, 0.6);
              cursor: pointer;
              transition: $main-transition-time;

              &::before {
                font-size: 18px;
                color: rgba(255, 255, 255, 0.6);
              }
              &.active,
              &:hover {
                font-weight: 600;
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 0.1);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  @include media-md {
    display: none;
  }
}
/* Content */

.post-login-content-wrap {
  padding-left: 32px;
  .post-login-content {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 476px;

    @include media-md {
      margin-right: auto;
      margin-left: auto;
    }
    .container {
      margin: 0;
      padding: 0;
    }
    .field-input .input-root,
    .field-select label + div,
    .field-input .phone-number-wrapper {
      margin-top: 0;
    }
    .field-input label,
    .field-select label {
      transform: none;
      align-items: flex-start;
      height: 40px;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: rgba(255, 255, 255, 0.6);
      transition: $main-transition-time;

      &[data-shrink="true"] {
        top: 4px;
      }
      &[data-shrink="false"] {
        top: 14px;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0;
        text-align: left;
      }
    }
    .gridItem-root__phoneNumber .field-input label {
      top: 5px;
    }
    .input-underline {
      &:before,
      &:after {
        content: none;
      }
    }
    &.responsible-gambling__content {
      .underlined-title {
        display: none;
      }
    }
    .gridItem-root__lastNamePrefix .field-input {
      label[data-shrink="false"] {
        top: 4px;
        font-size: 12px;
        line-height: 20px;
      }

      input[name="lastNamePrefix"] {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 0;
        cursor: not-allowed;
      }
    }
  }
  @include media-md {
    padding: 0;
  }
}
.button-wrapper {
  .button-root {
    margin: 20px 0;
  }
}
