.unexpected-error {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: calc(100vh + 70px);
  margin-top: -70px;
  background: {
    position: center;
    repeat: no-repeat;
    size: cover;
  }

  &__content {
    max-width: 670px;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
  }
  &__title {
    color: #fff;
    font-size: 31px;
    font-weight: $font-weight-base;
    text-transform: uppercase;
  }
  &__text {
    margin-bottom: 40px;
    color: #fff;
    font-size: 16px;
  }
  &__button {
    display: inline-block;
    padding: 16px 40px;
    font-size: 21px;
    text-transform: uppercase;
    font-weight: $font-weight-alt;
    text-decoration: none;
    cursor: pointer;
  }
}
