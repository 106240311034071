.title-main {
  font-family: $font-accent;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include media-to-md {
    margin: 40px 0 25px 0;
  }
  @include media-md {
    margin: 15px 0;
    text-align: center;
  }
}
