.game-slider {
  overflow: hidden;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    @include media-xs {
      min-height: 112px;
    }
  }

  &__wrapper--wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }

  &__slide {
    display: flex;
    flex: none;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 180px;
    margin: 0;
  }
  &__nav-prev,
  &__nav-next {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 47px;
    color: #ababab;
    background: #2d2d2d;
    transition: $main-transition-time;

    @include media-to-md {
      top: 2px;
    }
    @include media-md {
      top: 18px;
    }
    @include media-sm {
      display: none;
    }
    &::before {
      // 1
      // Something wrong with icon glyph positioning causes its crop when rendering in Safari (both desktop and mobile)
      // This combination of properties and values fixes that behavior in Safari and leaves icon glyph proper rendering in other browsers
      @include svg-icon(custom);
      content: "\004e";
      position: absolute;
      font-size: 14px;                // 1
    }
    &.is-disabled {
      color: #575757;
      pointer-events: none;
    }
  }
  &__nav-next {
    right: 22px;
  }
  &__nav-prev {
    right: 63px;
  }
  &__nav-prev {
    &::before {
      transform: rotateY(180deg);
    }
  }
  &__loading {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    padding-bottom: 14.1%;
    opacity: .6;
    background-color: color(primary, light);

    @include media-md {
      padding-bottom: 17.65%;
    }
    @include media-sm {
      padding-bottom: 23.7%;
    }
    @include media-xs {
      padding-bottom: 34.8%;
    }
  }
  &__loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &--home-page {
    overflow: hidden;
  }
  &--game-page {
    .game-thumbnail__badges {
      top: 0;
      right: 0;
    }
  }
}


