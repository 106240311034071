.other-documents-form {
  padding: 20px;
  //for iOS bug when confirm button is out of screen
  .mobile &,
  .tablet & {
    min-height: 800px;
    align-content: flex-start;
  }
  &__form {
    .imageField-buttons {
      @include media-sm {
        width: 100%;
        max-width: 360px;
        margin: auto;
      }
    }
  }
  &__confirm-button {
    margin-top: 20px;
  }
  .field-helperError,
  .imageField-helper {
    text-align: center;
  }
  .field-helperError {
    margin-bottom: 10px;
  }
  &__reset {
    margin-bottom: 30px;
  }
  &__images {
    display: flex;
  }
  &__image-stacker {
    flex: 1;
    min-height: 200px;
    perspective: 900px;
  }
  &__image-stacker-item {
    z-index: 1;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s;
    transform-origin: bottom;
    &:hover {
      & ~ .other-documents-form__image-stacker-item {
        transform: rotateX(-60deg);
      }
    }
  }
  &__image-item {
    &:not(.galleryItem-clone) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: none !important;
    }
    &.galleryItem-clone {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__max-file-count {
    color: #c5c5c5;
  }
  &__buttons {
    button {
      margin: 5px 10px 5px;
    }
  }
  &__confirmation {
    margin-bottom: 20px;
  }
  .imageField-root {
    width: 100%;
  }
  .fileField {
    &-button {
      padding: 13px 0;
      margin: 13px 0;
      p {
        margin: 0;
      }
      &::before {
        @include svg-icon;
        content: '\002a';
        margin-right: 10px;
        font-size: 16px;
        color: color(text, main);
      }
      > span:first-child {
        width: auto;
        color: color(text, light);
        text-transform: none;
        font-weight: $font-weight-base;
        text-decoration: underline;
      }
      &:hover {
        background: none;

        span {
          text-decoration: none;
        }
      }
    }
  }
  &__confirmation-text {
    text-align: left;
    padding-left: 20px;
  }
  &__restart {
    position: absolute;
    top: 28px;
    left: 28px;
    display: flex;
    min-width: 0;
    min-height: 0;
    padding: 0;
    font-size: 14px;
    border: 0;
    background: none;
    overflow: hidden;
    transition: all 0.2s;
    &:before {
      content: 'b';
      display: inline-block;
      font-family: 'Glyphter';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      text-transform: initial;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 5px;
      font-size: 15px;
      transform: rotate(180deg);
    }
    .button-label {
      transform: translateY(-100%);
      transition: transform .2s;
    }
    &:hover {
      color: #339999;
      background: none;

      .button-label {
        transform: translateY(0);
      }
    }
  }
  .imageField {
    &-selected {
      .imageField-imageWrapper {
        position: relative;
        align-self: center;

        &::before {
          @include svg-icon;
          content: '\0043';
          position: absolute;
          top: 20px;
          left: 20px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
    &-helper {
      margin: 8px 0 24px;
    }
    &-image {
      max-width: 100%;
      max-height: 300px;
    }
  }
  .galleryItem-delete {
    background: #fffc;
    color: #000;
    z-index: 1;
    right: 8px;
    top: 8px;
    position: absolute;
    animation: none;
    &:hover {
      background: #000c;
      color: #fff;
    }
  }
}
