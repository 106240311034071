
.depositErrorMethodsModal {
  &__stepper {
    width: 320px;
    margin: 45px auto;
    @media (max-width: $size-sm) {
      margin: 24px auto;
    }
  }
  &__step {
    svg {
      opacity: 0.4;
    }
    &.active, &.completed {
      svg {
        opacity: 1;
      }
    }
    & div:nth-child(2) {
      opacity: 0.4;
    }
    &.completed {
      & div:nth-child(2) {
        opacity: 1;
      }
    }
  }
  &__limit-title, &__welcome-title {
    p {
      font-size: 24px;
      text-transform: uppercase;
      font-family: $font-accent;
      font-weight: $font-weight-alt;
      margin: 0;
      letter-spacing: 2px;
    }
  }
  &__limit-subtitle {
  }
  &__confirm-btn {
    width: 100%;
  }
  &__welcome {
  }
  &__welcome-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__welcome-body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 24px
  }
  &__welcome-content {
    flex: 1 1 auto;
    margin-bottom: 15px;
  }
  &__welcome-subtitle {}
  &__welcome-image {
    margin-top: -10px;
    width: 100%;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  &__welcome-btn {
    @media (max-width: $size-sm) {
      position: fixed;
      bottom: 16px;
      left: 16px;
      right: 16px;
    }
  }
}
