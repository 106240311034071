.game-info-page {
  width: 100%;
  height: 100%;

  &__inner {
    display: flex;
    width: 100%;
    min-height: 100%;
  }
  &__content {
    z-index: 1;
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px 15px 15px;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__title {
    margin: 0 0 3px;
    font-family: $font-accent;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: center;
    text-transform: uppercase;
  }
  &__subTitle {
    margin: 0 0 25px;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(22, 22, 22, 0) 66.47%, #161616 100%),
      linear-gradient(0deg, rgba(22, 22, 22, 0.8), rgba(22, 22, 22, 0.8));
      z-index: -1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(20px);
      z-index: -1;
    }
  }
  &__button_favourite {
    padding: 0;

    &::before {
      padding: 0;
    }
  }
  &__button_favourite_on {
    &::before {
      @include svg-icon;
      content: '\0058';
      color: color(error);
    }
  }
  &__icon {
    display: block;
    margin-bottom: 22px;

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      border-radius: 8px;
    }
  }
  &__license-info {
    text-align: center;

    img {
      max-width: 100px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button {
    flex: 1;
    min-width: auto;
    margin: 0 4px;
    font-size: 14px;
    text-transform: capitalize;
  }
  &__error {
    margin: 15px 4px 0 4px;
  }
  &__rtp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.1);

    p {
      margin: 0;
      padding: 3px 10px;
    }
  }
}
