.ipoker-login-page {
  &__spinner-wrapper {
    padding: 30px 50px;
    background-color: white;
  }

  &__page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
}