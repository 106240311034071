.profile-popup {
  &__content {
    @include media-to-md {
      padding: 24px 24px 0;
    }
    @include media-md {
      padding: 15px 15px 0;
    }
  }
  &__user {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 65px;

    &.profile-popup__item {
      padding-top: 0;
    }
    &.icon {
      &::before {
        position: absolute;
        left: 0;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 8px;
        color: rgba(22, 22, 22, 1);
        background: rgba(254, 190, 16, 1);
      }
      &::after {
        @include svg-icon(custom);
        content: "\004e";
        position: absolute;
        top: 16px;
        color: #737373;

        @include media-to-md {
          right: 0;
        }
        @include media-md {
          right: 5px;
        }
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
    &::before {
      padding-right: 11px;
      padding-left: 3px;
      color: #737373;
    }
  }
  &__username {
    display: flex;
    gap: 4px;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
  }
  &__email {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #737373;
  }
  &__item {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &__links {
    display: flex;
    flex-direction: column;
    gap: 14px;
    border-top: 1px solid #2d2d2d;
    border-bottom: 1px solid #2d2d2d;
  }
  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    text-transform: none;

    .button-label {
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      text-decoration: none;
    }
  }
  &__last-login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    color: #737373;
    background: rgba(255, 255, 255, 0.05);
  }
  span {
    &:empty {
      display: none;
    }
  }
}
