.activation-page {
  min-height: calc(100vh - #{$header-expanded-height} - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @include media-md {
    min-height: calc(100vh - #{$header-collapsed-height} - 40px);
  }
}
