.loyalty-program {
  $spacer: (
    lg: 50px,
    sm: 30px
  );
  $color-brand: color(primary, main);
  $color-muted: #a09f9f;
  $color-minor: #c3c3c3;

  @mixin spacing {
    &:not(:first-child) {
      margin-top: map-get($spacer, lg);

      @include media-sm {
        margin-top: map-get($spacer, sm);
      }
    }
    &:not(:last-child) {
      margin-bottom: map-get($spacer, lg);

      @include media-sm {
        margin-bottom: map-get($spacer, sm);
      }
    }
  }

  padding: 10px 15px map-get($spacer, lg);

  @include media-sm {
    padding: 10px 0 map-get($spacer, sm);
  }

  // Reset
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
  }

  // Description
  &__description {
    @include spacing;

    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  // Steps
  &__steps {
    $icon-steps-size: 96px;

    @include spacing;

    @include media-sm {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-list {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      width: auto;
      margin: 0 -10px;

      @include media-sm {
        display: inline-block;
        margin: 0;
      }
    }
    &-line-through {
      position: absolute;
      z-index: 1;
      top: calc($icon-steps-size / 2);
      left: 50%;
      transform: translate(-50%, -50%);
      // `width` is calculated based on the number of steps
      height: 16px;
      background: $color-brand;

      @include media-sm {
        top: calc($icon-steps-size / 2);
        bottom: calc($icon-steps-size / 2);
        left: calc($icon-steps-size / 2);
        transform: translate(-50%, 0);
        width: 16px !important;
        height: auto;
      }
    }
    &-item {
      position: relative;
      z-index: 2;
      padding: 0 10px;

      @include media-sm {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;

        &:not(:last-child) {
          margin-bottom: map-get($spacer, sm);
        }
      }

      &-icon {
        @include size($icon-steps-size);

        position: relative;
        margin: 0 auto;
        background: $color-brand;
        border-radius: 50%;

        @include media-sm {
          flex-shrink: 0;
          margin: 0;
        }

        &::before {
          @include svg-icon;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 64px;
        }

        &--step-1 {
          &::before {
            content: '\005f';
          }
        }
        &--step-2 {
          &::before {
            content: '\0060';
          }
        }
        &--step-3 {
          &::before {
            content: '\007b';
          }
        }
      }

      &-description {
        margin-top: 10px;
        text-align: center;

        @include media-sm {
          margin-top: 0;
          margin-left: 20px;
          text-align: left;
        }
      }
    }
  }

  // Form
  &__form {
    @include spacing;
  }

  // Progress
  &__progress {
    @include spacing;

    // Info
    &-info {
      margin-bottom: calc(map-get($spacer, lg) / 2);

      @include media-sm {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: map-get($spacer, sm);
      }

      &-title {
        margin-bottom: calc(map-get($spacer, lg) / 2);
        font: {
          size: 22px;
          weight: bold;
        }
        text-align: center;

        @include media-sm {
          margin-bottom: map-get($spacer, sm);
        }
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin: -10px;
        list-style: none;

        @include media-sm {
          display: inline-block;
          margin: 0;
        }
      }
      &-item {
        padding: 10px;

        @include media-sm {
          padding: 0;

          &:not(:last-child) {
            margin-bottom: map-get($spacer, sm);
          }
        }
      }
      &-block {
        &-title {
          margin-bottom: 15px;
        }
        &-content {
          p {
            font: {
              size: 30px;
              weight: bold;
            }
            line-height: 1;
          }

          &.has-icon {
            display: flex;

            img {
              position: relative;
              top: -3px;
              flex: 0 0 auto;
              display: block;
              margin-right: 5px;
            }

            p {
              &::first-letter {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    // Tiers
    &-tiers {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-sm {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    &-tier-current {
      @include size(200px);

      flex: 0 0 200px;
    }
    &-tiers-list {
      $border-width: 10px;

      flex: 1;
      position: relative;
      margin-left: map-get($spacer, lg);
      padding: $border-width * 3;
      background-color: #efefef;

      &::before {
        position: absolute;
        top: $border-width;
        right: $border-width;
        bottom: $border-width;
        left: $border-width;
        background-color: transparent;
        border: $border-width solid #fff;
        content: "";
        pointer-events: none;
      }

      @include media-md {
        margin-left: map-get($spacer, sm);
      }

      @include media-sm {
        margin-top: map-get($spacer, sm);
        margin-left: 0;
      }
    }
    &-tiers-item {
      padding: 10px;
      text-align: center;

      &-icon {
        @include size(70px);

        position: relative;
        margin: 0 auto 15px;
        background-color: #fff;
        border-radius: 50%;
        img {
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        span {
          position: absolute;
          z-index: 2;
          top: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 2px 4px;
          background-color: #e1e1e1;
          color: $color-muted;
          content: attr(data-amount);
          white-space: nowrap;

          .is-current & {

          }
        }
      }
      &-title {
        &::first-letter {
          text-transform: uppercase;
        }
      }
      &-description {
        color: $color-muted;
      }

      &.is-current {
        ~ div {
          .loyalty-program__progress-tiers-item-icon {
            background-color: $color-minor;

            img {
              filter: contrast(0);
              mix-blend-mode: screen;
            }
            span {
              background-color: color(primary, main);
              color: color(primary, dark);
            }
          }
          .loyalty-program__progress-tiers-item-title,
          .loyalty-program__progress-tiers-item-description {
            color: $color-minor;
          }
        }
      }
    }
  }

  // Circular progress
  &__circular-progress {
    &-content {
      padding: 40px;
      text-align: center;
    }
    &-amount {
      font: {
        size: 48px;
        weight: bold;
      }
    }
  }

  // Tier expiry
  &__tier-expiry {
    margin-top: 10px;
    color: $color-muted;
    text-align: right;

    @include media-sm {
      text-align: center;
    }
  }

  // Help
  &__help {
    @include spacing;
  }
}
