.game-list {
  position: relative;
  margin-bottom: 40px;

  @include media-md {
    margin-bottom: 30px;
  }

  @include media-sm {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 16px 0;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    text-transform: unset;
    cursor: default;

    &::before {
      margin-right: 5px;
      color: #FEBE10;
    }
  }
  &__title-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 11px;
    min-height: unset;
    border: none;
    border-radius: 16px;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    transition: $main-transition-time;

    &:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
    }
    @include media-to-sm {
      margin-left: 20px;
    }
    @include media-sm {
      margin-left: auto;
    }
    &::after {
      @include svg-icon(custom);
      content: "\004e";
      margin-left: 6px;
      font-size: 9px;
    }
  }
  &__empty,
  &__not-available {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    background: color(primary, main);
  }
  &__not-available {
    #root[data-url="/group/all"] &,
    #root[data-url="/all-games"] & {
      grid-column: auto / span 4;

      @include media-md {
        grid-column: auto / span 2;
      }
    }
  }
  &__games--grid {
    margin: 0;
    max-width: 100%;
    .game-list__games-wrapper {
      display: grid;
      flex-shrink: 0;
      grid-template-columns: repeat(5, 1fr);

      .mobile &,
      .tablet & {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media-sm {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-to-md {
        grid-gap: 24px;
      }
      @include media-md {
        grid-gap: 16px;
      }
      .featured-games-slider {
        grid-area: 1 / 1 / 3 / 3;
      }
      .game-thumbnail {
        width: 100%;
        margin: 0;
      }
    }
  }
  &__buttons-panel {
    text-align: center;
    grid-column: 1 / 6;

    .mobile &,
    .tablet & {
      grid-column: 1 / 3;
    }
    @include media-sm {
      grid-column: 1 / 3;
    }
  }
  &__button {
    @include media-xs {
      min-width: 120px;
    }
  }
  &__section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 24px 0;
    @include media-sm {
      margin: 24px 0;
    }
  }
  &__title_count {
    margin: 0;
  }
  &__sortBtn {
    padding: 10.5px 6px;
  }
  &__sortBtn:first-of-type {
    margin-left: auto;
    margin-right: 16px;
  }
}
