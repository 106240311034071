.profile-account-form {
  .payment-select-field__img {
    margin: 0;
  }
}

/** Bank account */
.profile-account-form--1001 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--bankAccount,
      &--bankName,
      &--bankAccountOwnerName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** IBAN */
.profile-account-form--1011 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--ibanAccountOwnerName,
      &--address,
      &--ibanAccount,
      &--ibanBankName,
      &--bankLocation,
      &--bankCity,
      &--beneficiaryCity,
      &--beneficiaryCountry,
      &--beneficiaryState,
      &--beneficiaryZipCode,
      &--beneficiaryStreet,
      &--beneficiaryHouseNumber,
      &--beneficiaryAddress,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** Entercash - EUR */
.profile-account-form--1079 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--ibanAccountOwnerName,
      &--ibanAccount,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** Entercash - not EUR */
.profile-account-form--1080 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--bankAccountOwnerName,
      &--bankAccount,
      &--bankBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/
