.info-block {
  background: var(--gradient);

  @include media-to-md {
    padding: 40px 0 0;
  }
  @include media-md {
    overflow: auto;
    padding: 20px 0 30px;
  }

  &__content {
    display: grid;
    gap: 24px;

    @include media-to-md {
      grid-template-columns: 1fr 1fr;
    }
    @include media-md {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__title,
  &__text {
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0;
    color: #abaeb3;
  }
  &__text {
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    color: #abaeb3;
  }
}
