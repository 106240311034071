.typography {
  font-size: 14px;
  color: color(text, main);

  h1 {
    position: relative;
    margin: 24px 0 10px;
    font-family: $font-accent;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.04em;
  }
  .underlined-title {
    h1 {
      display: inline-block;
      text-transform: uppercase;

      @include media-xs {
        font-size: 22px;
        margin-bottom: 14px;
      }
    }
  }
  h2 {
    margin: 16px 0 4px;
    font-family: $font-accent;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;

    @include media-xs {
      font-size: 16px;
    }
  }
  h3 {
    font-size: 15px;
    font-weight: $font-weight-alt;
    text-transform: uppercase;

    @include media-xs {
      font-size: 14px;
    }
  }
  p,
  li,
  tr {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);
  }
  ul,
  ol {
    list-style-type: none;
    padding-left: 25px;

    li {
      margin-bottom: 4px;
      position: relative;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: -14px;
        width: 4px;
        height: 4px;
        background-color: #fff;
      }
    }
  }
  img {
    max-width: 100%;
  }
}
