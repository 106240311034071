.banner {
  @include media-to-md {
    padding-top: 180px;
    padding-bottom: 160px;
  }
  @include media-md {
    width: 100%;
    padding-top: 160px;
  }
  &__container {
    position: relative;
    margin-top: -$header-expanded-height;
    margin-bottom: 77px;
    background-repeat: repeat-x;
    background-position-y: bottom;

    @media (min-width: 1281px) {
      background-size: cover;
    }
    @media (max-width: 1280px) {
      background-size: cover;
    }
    @include media-md {
      margin-top: -$header-collapsed-height;
      margin-bottom: -32px;
    }
  }
  &__image {
    @include media-to-md {
      position: absolute;
      width: 100%;
      right: 0;
    }
    img {
      width: 100%;
      object-fit: contain;

      @include media-to-md {
        max-width: 755px;
        max-height: 640px;
        margin-left: auto;
      }
      @include media-md {
        margin: auto;
      }
      @include media-md-landscape {
        max-height: 420px;
      }
    }
  }
  &__image-container {
    @include media-to-md {
      position: relative;
    }
    @include media-md {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &__content {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 13px;
    max-width: 450px;
    height: 370px;

    @include media-md {
      margin: auto;
    }
  }
  &__subTitle {
    margin: 0;
    font-family: $font-primary;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: unset;
    color: #FEBE10;

    @include media-to-md {
      text-align: left;
    }
    @include media-md {
      text-align: center;
    }
  }
  &__title,
  &__title p,
  &__title strong {
    margin: 0;
    font-family: $font-accent;
    text-transform: uppercase;
    color: #fff;

    strong {
      color: #FEBE10;
    }

    @include media-to-md {
      font-size: 48px;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 0.04em;
      text-align: left;
    }
    @include media-md {
      font-size: 32px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.04em;
      text-align: center;
    }
  }
  &__text {
    margin: 0;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);

    @include media-to-md {
      text-align: left;
    }
    @include media-md {
      text-align: center;
    }
  }
  &__responsible-text {
    font-family: $font-primary;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.8);

    @include media-to-md {
      text-align: left;
    }
    @include media-md {
      text-align: center;
    }
  }
  &__button {
    position: relative;

    @include media-to-md {
      width: max-content;
      margin: 28px 128px 0 0;
      padding-block: 23px;
    }
    @include media-md {
      margin: 20px auto;
      width: 100%;
      max-width: 370px;
    }
    &::before {
      content: "";
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 32px;
      background-image: url("/cms/img/banners/VHKA.png");
      background-repeat: no-repeat;
      background-size: contain;

      @include media-to-md {
        top: 50%;
        right: -30px;
        transform: translate(100%, -50%);
      }
      @include media-md {
        bottom: -100%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
  &__swiper {
    width: 100%;

    .swiper-wrapper {
      display: inline-flex;
    }
  }
}
.banner-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 75px;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }
  @include media-to-md {
    margin-top: -145px;
  }
  @include media-md {
    position: relative;
    flex-direction: column;
  }
  &__step {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    width: 100%;
    max-width: 400px;
    min-height: 98px;
    border-radius: 8px;
    background-color: #2d2d2d;

    @include media-to-md {
      padding: 35px 30px 35px 111px;
    }
    @include media-md {
      padding: 16px 16px 16px 100px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85px;
      height: 85px;
      background-position: center;
      background-repeat: no-repeat;

      @include media-to-md {
        left: 18px;
      }
      @include media-md {
        left: 11px;
      }
    }
  }
  &__title {
    margin: 0;
    font-family: $font-accent;
    font-size: 16px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
  }
  &__text {
    margin: 0;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
  }
  &__1 {
    &::before {
      background-image: url("/cms/img/banners/steps/1.png");
    }
  }
  &__2 {
    &::before {
      background-image: url("/cms/img/banners/steps/2.png");
    }
  }
  &__3 {
    &::before {
      background-image: url("/cms/img/banners/steps/3.png");
    }
  }
}
