.expiring-verification-code {
  text-align: center;

  &__progress {
    svg {
      max-width: 36px;
      display: inline;
    }
  }
  &__code {
    font-size: 30px;
    font-weight: bold;
  }
}
