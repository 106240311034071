.ipoker-deposit-page {
  &__page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  &__root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
  }

  &__resume-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 700px;
    padding: 20px 50px;

    background-color: white;
  }
}
