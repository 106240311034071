[data-url*='/all-games'] .home-menu__wrapper .home-menu {
  margin-bottom: 0;
}
.home-menu__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: $header-collapsed-height;
  z-index: 20;
  border: none;
  transition: $main-transition-time;
  padding: 6px 0;
  box-shadow: var(--header-box-shadow);

  .home-menu {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    width: inherit;
    margin: 40px 0;
    overflow-x: auto;
    transition: $main-transition-time;

    .settings-menu__item-override {
      width: inherit;
      transition: $main-transition-time;

      a {
        max-width: 191px;
        width: inherit;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 12px;
        border: none;
        border-radius: 4px;
        background: color(primary, dark);
        text-transform: uppercase;
        text-decoration: none;
        font-family: $font-accent;
        font-weight: 600;
        font-size: 14px;
        color: #D7C2FF;
        white-space: nowrap;
        transition: $main-transition-time;

        @include media-sm {
          min-width: 123px;
          height: 65px;
          padding: 0;
          border-radius: 0;
          font-size: 12px;
        }
        &::before {
          padding-bottom: 10px;
          color: #D7C2FF;
          font-size: 16px;
          transition: $main-transition-time;
        }
        &:hover,
        &.active {
          border-color: color(primary, main);
          background: color(primary, main);
          color: #FEBE10;

          &::before {
            color: #FEBE10;
          }
        }
      }
    }
    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @include media-md {
        padding: 0;
      }
    }
    &.swiper-root {
      overflow-x: hidden;
      padding: 0 20px;

      .swiper-wrapper {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 100%;
      }
      @include media-sm {
        margin: 20px 0 0 0;
        padding: 0;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: color(secondary, light);
      border-radius: 4px;
    }
  }
  &.home-menu__wrapper--scrolled {
    .home-menu {
      margin: 0;
    }
    @include media(null, $size-md) {
      background-color: color(background, header);

      .settings-menu__item-override {
        a {
          height: auto;
          flex-direction: row;
          padding: 8px 12px;
          font-size: 14px;
          &:before {
            padding: 0 6px 0 0;
            font-size: 16px;
          }
          &:empty:before {
            padding: 0;
          }
        }
      }
    }
  }
  @include media-md {
    top: 111px;
    background-color: #0f0f0f;
  }
  @include media-sm {
    padding: 0;
  }
}
