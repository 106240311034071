.game-name-filter {
  &__icon {
    position: relative;

    &::before {
      @include svg-icon;
      content: '\0059';
      vertical-align: middle;
      font-size: 18px;
      font-weight: 700;
      @include media-sm {
        font-size: 15px;
      }
    }
  }
}
