  .gd-window__game__neccton {
    .aspectRatio-content {
      position: relative;
    }

    .aspectRatio-container {
      padding-top: 0 !important;
    }

    & > div {
      max-width: 100% !important;
    }
  }

  #neccton-iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }

  #root[data-url="/my/neccton"] {
    .account__title {
      display: none;
    }
    .post-login-content-wrap--neccton {
      padding-top: 0;
    }
  }
