.home-slider {
  display: flex;
  flex-direction: column;

  @include media-to-md {
    margin-bottom: 80px;
    margin-top: 50px;
  }
  @include media-md {
    margin-bottom: 33px;
  }
  @keyframes transition {
    0% {
      filter: blur(5px);
    }
    100% {
      filter: none;
    }
  }
  &__main {
    display: flex;

    @include media-to-md {
      flex-direction: row;
      gap: 96px;
      margin-bottom: 46px;
    }
    @include media-md {
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin-bottom: 30px;
    }
    &.in-process {
      animation: transition 1s;
    }
  }
  &__img {
    @include custom-border;
    @include media-md {
      order: -1;
    }
    img {
      border-radius: 8px;

      @include media-md {
        width: 100%;
      }
    }
  }
  &__bg {
    z-index: -1;
    position: absolute;
    inset: 0;
    filter: blur(30px) brightness(0.3);
    transition: $main-transition-time;

    img {
      width: 100%;
      border-radius: 8px;
      height: 100%;
      max-height: 600px;
      object-position: top;
      object-fit: cover;
    }
  }
  &__title {
    margin: 0;
    padding: 0;
    font-family: $font-accent;
    font-size: 40px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    @include media-to-md {
      font-size: 40px;
      line-height: 38px;
    }
    @include media-md {
      font-size: 32px;
      line-height: 30px;
      text-align: center;
    }
  }
  &__description {
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);

    @include media-md {
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__chip {
    display: flex;
    flex-direction: row;
    gap: 8px;

    &__text {
      margin: 0;
      padding: 3px 16px;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
      text-align: left;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.1);

      @include media-to-md {
        line-height: 26px;
      }
      @include media-md {
        line-height: 22px;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-to-md {
      gap: 24px;
    }
    @include media-md {
      align-items: center;
      gap: 33px;
    }
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    @include media-md {
      justify-content: center;
      width: 100%;
    }
  }
  &__providerImg {
    @include media-to-md {
      margin-left: 18px;
    }
  }
  &__button {
    @include media-md {
      width: 100%;
    }
  }
}
.home-slider-small {
  display: grid;
  gap: 8px;

  @include media-to-md {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include media-md {
    grid-template-columns: 1fr;
  }
  &__item {
    display: flex;
    cursor: pointer;

    @include media-to-md {
      gap: 24px;
      padding: 24px;
    }
    @include media-md {
      gap: 16px;
      padding: 16px;
    }
    &.active {
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      transition: 0.5s;
    }
  }
  &__title {
    margin: 0;
    padding: 0;
    font-family: $font-accent;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
  }
  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Определяем количество отображаемых строк */
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__img {
    @include custom-border;
    height: 64px;

    img {
      width: 86px;
      height: 100%;
      border-radius: 8px;
    }
  }
}
