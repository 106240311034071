.main-menu {
  @include media-to-md {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    & &__link {
      position: relative;
      gap: 8px;
      height: 100%;
      padding: 0;
      border: none;
      background: none;
      transition: $main-transition-time;

      &:hover {
        filter: none;

        &::before {
          color: #FEBE10;
        }
        .button-label {
          color: #fff;
        }
      }
      &.active {
        &::before {
          color: #FEBE10;
        }
        .button-label {
          color: #fff;
        }
      }
      &::before {
        color: rgba(255, 255, 255, 0.65);
        transition: $main-transition-time;
      }
      .button-label {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.65);
        transition: $main-transition-time;
      }
    }
  }
  @include media-md {
    display: none;
  }
}
