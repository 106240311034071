.validation-form {
  &__document-type {
    .field-select {
      label {
        text-transform: uppercase;
      }
    }
  }
  &__notification {
    margin-top: 20px;
    margin-bottom: -10px;
  }
  &__image-field {
    > .imageField-helper {
      text-align: center;
      margin-bottom: 16px;
    }
  }
  &__file-content {
    @include media-sm {
      max-width: 310px;
    }
  }
  &__document-type-selector {
    .select-menuPaper {
      margin-top: 10px;
    }
  }
  &__button {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  &__document-reset {
    .button-default {
      color: color(text, light);
      font-weight: $font-weight-base;
    }
  }
  &__waiting,
  &__approved {
    margin-top: 30px;
  }
  &__confirmation-text {
    text-align: left;
  }
  &__images {
    display: flex;
  }
  &__image-stacker {
    flex: 1;
    min-height: 200px;
    perspective: 900px;
  }
  &__image-stacker-item {
    z-index: 1;
    background-color: #eee;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s;
    transform-origin: bottom;
    &:hover {
      & ~ .validation-form__image-stacker-item {
        transform: rotateX(-60deg);
      }
    }
  }
  &__confirmation-text {
    padding-left: 20px;
  }
  &__buttons {
    margin-top: 10px;
  }
  &__button {
    margin: 5px;
  }
  &__image-item {
    &:not(.galleryItem-clone) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: none !important;
    }
    &.galleryItem-clone {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__max-file-count {
    color: #c5c5c5;
  }
  &__restart {
    position: absolute;
    top: 28px;
    left: 28px;
    display: flex;
    min-width: 0;
    min-height: 0;
    padding: 0;
    font-size: 14px;
    border: 0;
    background: none;
    overflow: hidden;
    transition: all 0.2s;
    &:before {
      content: 'b';
      display: inline-block;
      font-family: 'Glyphter';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      text-transform: initial;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 5px;
      font-size: 15px;
      transform: rotate(180deg);
    }
    .button-label {
      transform: translateY(-100%);
      transition: transform .2s;
    }
    &:hover {
      color: #339999;
      background: none;

      .button-label {
        transform: translateY(0);
      }
    }
  }
  .fileField {
    &-root {
      width: 100%;
      margin-top: 15px;
      align-items: center;
    }
    &-button {
      &::before {
        @include svg-icon;
        content: '\002a';
        margin-right: 5px;
        font-size: 16px;
        color: #fff;
      }
      > span:first-child {
        width: auto;
        color: color(text, light);
        text-transform: none;
        font-weight: $font-weight-base;
        text-decoration: underline;
      }
      &:hover {
        background: none;

        span {
          text-decoration: none;
        }
      }
    }
  }
  .imageField {
    &-root {
      width: 100%;
      margin-top: 20px;
      align-items: center;
    }
    &-selected {
      .imageField-imageWrapper {
        position: relative;

        &::before {
          @include svg-icon;
          content: '\0043';
          position: absolute;
          top: 20px;
          left: 20px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
    &-image {
      max-width: 100%;
      max-height: 300px;
    }
    &-buttons {
      width: 100%;
    }
  }
  .galleryItem-delete {
    background: #fffc;
    color: #000;
    z-index: 1;
    right: 8px;
    top: 8px;
    position: absolute;
    animation: none;
    &:hover {
      background: #000c;
      color: #fff;
    }
  }
  &__stepper {
    .stepper-step div:nth-child(2) {
      top: calc(100% - 19px);
      left: calc(50% + 17px);
      right: calc(-50% + 17px);
    }
  }
}
