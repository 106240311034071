.static-hero {
  position: relative;
  margin-top: -70px;
  min-height: 514px;
  display: flex;
  justify-content: center;
  background-image: url('/cms/img/static-pages/about-us.jpg');
  background-size: cover;
  background-position: center;

  @include media-md {
    margin-top: -60px;
    min-height: 320px;
  }
  @include media-xs {
    min-height: 200px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @include media-sm {
    margin-bottom: 14px;
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
  &__title {
    margin: 66px 0 20px;
    font-size: 31px;
    color: #fff;
    text-align: center;
  }
  &__link {
    display: block;
    padding: 16px 54px;
    font-size: 21px;
    text-align: center;
    text-decoration: none;

    @include media-xs {
      padding: 10px 30px;
      font-size: 14px;
    }
  }
}
