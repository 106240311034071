.balance {
  @include media-to-md {
    padding: 24px;
  }
  @include media-md {
    padding: 15px;
  }
  .icon {
    &::before {
      color: #737373;
    }
  }
  &__title {
    display: flex;
    margin: 0 0 14px;
    padding: 0;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
  }
  &__subTitle {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }
  &__text {
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #747474;

    a {
      margin: 0;
      padding: 0;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      color: #747474;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin-bottom: 17px;
    padding: 0 0 0 15px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.1);

    .icon {
      display: flex;
      align-items: center;

      &::before {
        padding-right: 10px;
        color: rgba(254, 190, 16, 1);
      }
    }
  }
  &__buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;

    button {
      width: 100%;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__available {
    margin-bottom: 6px;
  }
  &__reserved {
    margin-bottom: 16px;
  }
  &__promo {
    margin-bottom: 15px;
    padding: 15px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  &__transactions {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
    text-decoration: none;

    &::after {
      @include svg-icon(custom);
      content: "\004e";
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #737373;
    }
  }
  &__real {
    padding-bottom: 15px;
  }
  &__wagering-requirement {
    position: relative;

    .balance__value {
      padding: 10px 5px 0 5px;

      .progress {
        height: 1.15em;
        border-radius: 10px;
        background-color: color(limit-amount, progress-back);

        .progress-bar {
          border-radius: 10px;
          background: color(limit-amount, progress-path);
        }
      }
    }
  }
}
