.ipoker-login {
  &__root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
  }

  &__form-wrapper {
    padding: 20px 50px;

    background-color: white;
  }

  &__form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;

    padding: 20px 0;
  }
}