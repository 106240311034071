.loyalty-points-conversion-form {
  $spacer: (
    lg: 50px,
    sm: 30px
  );

  @mixin amount {
    margin-right: 10px;
    font: {
      size: 40px;
      weight: bold;
    }
    letter-spacing: -1px;
    white-space: nowrap;

    @include media-sm {
      font-size: 32px;
    }
  }

  // Row
  &__row {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include media-sm {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  // Award points
  &__award-points {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    min-width: 175px;
    height: 175px;
    padding-left: 45px;
    background: url("/cms/img/loyalty-points-conversion-form/bg-award-points.svg") transparent 0 50% no-repeat;
  }
  &__award-points-amount {
    @include amount;
  }

  // Convert
  &__convert {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 509px;
    min-height: 222px;
    margin-left: map-get($spacer, lg);
    padding: 40px 50px 30px 40px;
    background: url("/cms/img/loyalty-points-conversion-form/bg-convert.svg") transparent 50% no-repeat;
    background-size: 100% 100%;

    @include media-md {
      margin-left: map-get($spacer, sm);
    }

    @include media-sm {
      margin-top: map-get($spacer, sm);
      margin-left: 0;
    }
  }
  &__convert-title {
    margin-bottom: 10px;
    font: {
      size: 18px;
      weight: bold;
    }
    text-transform: uppercase;
  }
  &__convert-warning {
    &.notification-root {
      flex-grow: 0;
      background-color: transparent;
      border-color: #333;

      &::before {
        margin: 0 -10px 0 10px;
        background-color: transparent;
        color: #333;
      }

      p {
        color: #333;
        font-size: 14px;
      }
    }
  }
  &__convert-money {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  &__convert-money-amount {
    @include amount;
  }
  &__convert-action {
    min-width: 240px;
    color: #fff;
    background-color: #333;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    text-transform: uppercase;

    &:hover {
      color: #fff;
      background-color: lighten(#333, 8%);
    }
  }
}
