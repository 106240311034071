/* Layout */
#root[data-url="/promotions"] {
  .promotions__card-list {
    grid-template-columns: repeat(2, 1fr);

    .promotion-card {
      &__body {
        width: 100%;
      }
      &__image {
        width: 230px;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__claim-btn {
        max-width: 210px;

        @include media-xs {
          padding: 16px;
        }
        @media (max-width: 390px) {
          padding: 10px;
        }
      }
    }

    @include media-md {
      grid-template-columns: 1fr;

      .promotion-card {
        &__body {
          width: 100%;
        }
        &__image {
          height: 100%;
          @media (max-width: 390px) {
            width: 225px;
            height: 175px;
          }
        }
      }
    }

    @include media-xs {
      grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
    }
  }
}

.static-page {
  &__container {
    @include media-to-md {
      margin-top: 15px;
    }
  }
  &__menu {
    padding-right: 15px;

    @include media-md {
      display: none;
    }

    & + .static-page__content {
      @include media-to-md {
        padding-left: 33px;
      }
    }
  }
}

/* Menu */

.static-page-menu {
  height: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 190px;

  &__item {
    position: sticky;
    position: -webkit-sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .settings-menu__item-override {
      > a {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 42px;
        padding: 12px 30px;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        color: var(--static-item-color);
        text-decoration: none;
        transition: $main-transition-time;

        &:hover,
        &.active {
          border-radius: 8px;
          color: #fff;
          background-color: rgba(255, 255, 255, 0.1);
        }
        &.active {
          font-weight: 600;
        }
        &::before {
          color: var(--static-item-color);
        }
      }
    }
  }
}

/* Promotions-static */

.promotions-static {
  text-align: center;
}

/* Other */

#static-page__anchor {
  position: relative;
  top: -75px;
}
