.is-poker-app .header__group-left, .is-poker-app .header__group-right {
  opacity: 0;
  pointer-events: none;
}

.is-poker-app .header__logo {
  pointer-events: none;
}

.is-poker-app .main-decorator__footer {
  display: none;
}
