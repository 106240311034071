.blocked-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  gap: 15px;
  background: rgba(22, 22, 22, 1);

  &__bg {
    overflow: hidden;
    position: relative;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(59, 25, 120, 1);

    @include media-to-md {
      width: calc(100vw - 48px);
      margin: 24px;
    }
    @include media-md {
      width: calc(100vw - 32px);
      margin: 16px;
    }
    @media (max-width: $size-md) and (orientation: landscape) {
      overflow: auto;
    }
    &::before {
      content: "";
      z-index: 5;
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(58, 25, 121, 0) 75.4%, #3A1979 100%);
    }
    &::after {
      content: "";
      z-index: 2;
      position: absolute;
      inset: 0;
      background-repeat: no-repeat;

      @include media-to-lg {
        background-size: contain;
      }
      @include media-lg {
        background-size: cover;
      }
      @include media-to-md {
        background-image: url("/cms/img/blocked-page/watermark.png");
        background-position: center right;
      }
      @include media-md {
        background-image: url("/cms/img/blocked-page/watermark-mobile.png");
        background-position: center;
      }
    }
  }
  &__container {
    position: relative;
    max-width: 1440px;
    height: 100%;
    margin-inline: auto;

    @include media-md {
      display: flex;
      flex-direction: column;
      gap: 45px;
    }
  }
  &__content {
    z-index: 6;
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-to-md {
      gap: 50px;
      max-width: 600px;
      margin-top: 6.5%;
      margin-left: 13.5%;
    }
    @include media-md {
      gap: 30px;
      margin-inline: auto;
      padding-inline: 16px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;

    @include media-to-md {
      gap: 18px;
    }
    @include media-md {
      gap: 16px;
    }
  }
  &__logo {
    position: relative;
    right: 0;
    max-height: 32px;
    width: 100%;

    @include media-to-md {
      margin-top: 48px;
    }
    @include media-md {
      margin-top: 32px;
      margin-bottom: 40px;
    }
  }
  &__image {
    @include media-to-md {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      height: 100%;
      max-height: 83%;
    }
    @include media-md {
      display: none;
    }
    @media (max-width: $size-md) and (orientation: landscape) {
      display: none;
    }
    &::after {
      content: "";
      z-index: 3;
      position: absolute;

      @include media-to-md {
        inset: 0;
        border-radius: 629.206px;
        opacity: 0.5;
        background: #8546FF;
        filter: blur(141.83807373046875px);
      }
      @include media-md {
        height: 100%;
        width: 100%;
        right: 0;
        left: 0;
        border-radius: 339px;
        opacity: 0.5;
        background: #8546FF;
        filter: blur(60px);
      }
    }
    img {
      z-index: 4;

      @include media-md {
        width: 100%;
        max-width: 320px;
        margin: auto;
      }
    }
  }
  &__title,
  &__title p,
  &__title strong {
    margin: 0;
    font-family: $font-accent;
    text-transform: uppercase;
    color: #fff;

    strong {
      color: #FEBE10;
    }

    @include media-to-md {
      font-size: 40px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0.04em;
      text-align: left;
    }
    @include media-md {
      font-size: 24px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.04em;
      text-align: center;
    }
  }
  &__title {
    p {
      @include media-to-md {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__text {
    margin: 0;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);

    @include media-to-md {
      max-width: 424px;
      text-align: left;
    }
    @include media-md {
      text-align: center;
    }
  }
  &__button {
    @include media-to-md {
      margin: 15px auto 0 0;
    }
    @include media-md {
      margin: 5px auto 0;
    }
  }
}
