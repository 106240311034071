.recommended-games {
  background: var(--gradient);

  @include media-to-md {
    padding: 40px 0;
  }
  @include media-md {
    overflow: auto;
    padding: 0;
  }

  &__items {
    display: flex;
    gap: 24px;
    width: 100%;
    overflow: auto;
  }
  &__game {
    overflow: hidden;
    position: relative;
    display: flex;
    flex: 1;
    min-width: 304px;
    border-radius: 8px;

    @include media-to-md {
      gap: 24px;
      flex-direction: row;
      padding: 50px 48px;
    }
    @include media-md {
      gap: 18px;
      flex-direction: column;
      padding: 8px 8px 16px;
    }
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  $mobileContentIndent: 16px;
  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 11px;
    flex: 1;

    @include media-md {
      padding: 0 $mobileContentIndent;
    }
  }
  &__info {
    display: flex;
    gap: 8px;

    &__item {
      padding: 2px 12px;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  &__main-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 16px 0;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    text-transform: inherit;

    &::before {
      margin-right: 5px;
      color: #FEBE10;
    }
  }
  &__title {
    margin: 5px 0 0 0;
    font-family: $font-accent;
    font-size: 24px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
  }
  &__text {
    margin: 0;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    flex: 1 1 auto;
  }
  &__button {
    white-space: nowrap;

    @include media-to-md {
      margin: 17.5px 0 0 0;
    }
    @include media-md {
      margin-top: 25px;
      margin-right: -$mobileContentIndent;
      margin-bottom: 0;
      margin-left: -$mobileContentIndent;
    }
    a {
      @include button-root;
      @include button-accent;
      margin: 0;
      font-family: $font-accent;
      font-size: 14px;
      font-weight: 600;
      line-height: 13px;
      letter-spacing: 0.04em;
      text-align: center;
      text-decoration: none;
      color: #3A1979;

      @include media-md {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
    p {
      margin: 0;
    }
  }
  &__bg {
    z-index: -1;
    position: absolute;
    inset: 0;
    filter: blur(20px);

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  &__picture {
    @include custom-border;
    width: 100%;
    display: inline-flex;
    flex: 1;
    margin: auto;
    @include media-md {
      flex: 0;
    }
    img {
      width: 100%;
      margin: auto;
      border-radius: 8px;
      object-fit: contain;
    }
  }
}
.custom-section {
  margin-top: 20px;
}
