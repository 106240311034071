.registration-form {
  .fields-container {
    justify-content: space-between;
    margin-top: 20px;

    @media (min-width: 768px) {
      &__grid-root {
        margin: 0 -10px;
        padding: 0 10px;
      }
    }
  }
  &__successful {
    justify-content: center;
    margin-top: 20px;
    text-align: center;

    .markdown-root {
      margin-bottom: 20px;
    }
  }
  &__gdpr-notification {
    font-size: 14px;
    text-align: justify;
    color: color(text, main);
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin: 20px auto 0;

    @include media-sm {
      margin-bottom: 20px;
    }
    button + button {
      margin-left: 30px;
    }
  }
  .fields-container__grid-root--ageChecked,
  .fields-container__grid-root--policyChecked,
  .fields-container__grid-root--ageAndPolicyChecked,
  .fields-container__grid-root--receiveEmail,
  .fields-container__grid-root--receiveSms,
  .fields-container__grid-root--receiveEmailAndSms,
  .fields-container__grid-root--statementPrivacyChecked,
  .fields-container__grid-root--statementLegislationChecked,
  .fields-container__grid-root--statementLegallyCompetentChecked,
  .fields-container__grid-root--statementOwnAccountChecked,
  .fields-container__grid-root--statementNoFraudAmlChecked,
  .fields-container__grid-root--statementIdentificationChecked,
  .fields-container__grid-root--privacyPolicyChecked,
  .fields-container__grid-root--statementPersonalUseAndMoney,
  .fields-container__grid-root--publicPerson,
  .fields-container__grid-root--campaignsEnabled {
    max-width: 100%;
    flex-basis: 100%;
    order: 10;
  }
  .fields-container__grid-root--receiveEmail {
    label {
      align-items: flex-start;
    }
  }
  .fields-container__grid-root--selected-country-ROU {
    &.fields-container__grid-root--birthDate {
      display: none;
    }
  }
  &__logging-in {
    margin-top: 10px;
    text-align: center;

    &-text {
      margin-top: 10px;
    }
  }
}

/* CDDN */
.registration--regular {
  .registration-form__buttons .button-default {
    display: none;
  }
  .registration-form--step2 .registration-form__buttons .button-accent {
    margin-left: 0;
    width: 100%;
    max-width: 200px;
  }
}
.registration-form--cddn {
  .registration-form__buttons .button-default,
  .fields-container__grid-root--lastName,
  .fields-container__grid-root--initials,
  .fields-container__grid-root--gender,
  .fields-container__grid-root--country,
  .fields-container__grid-root--city,
  .fields-container__grid-root--address,
  .fields-container__grid-root--lastName,
  .fields-container__grid-root--houseNumber,
  .fields-container__grid-root--zipCode,
  .fields-container__grid-root--birthDate,
  .fields-container__grid-root--birthPlace,
  .fields-container__grid-root--referralCode,
  .fields-container__grid-root--email,
  .fields-container__grid-root--birthCountry,
  .fields-container__grid-root--phoneNumber,
  .fields-container__grid-root--statementPrivacyChecked,
  .fields-container__grid-root--statementLegislationChecked,
  .fields-container__grid-root--statementLegallyCompetentChecked,
  .fields-container__grid-root--statementOwnAccountChecked,
  .fields-container__grid-root--statementNoFraudAmlChecked,
  .fields-container__grid-root--campaignsEnabled,
  .fields-container__grid-root--statementIdentificationChecked {
    display: none;
  }
  &.registration-form--step2 .registration-form__buttons .button-accent {
    margin-left: 0;
    width: 100%;
    max-width: 200px;
  }
}
.registration-form {
  .fields-container__grid-root--referralCode,
  .fields-container__grid-root--statementPrivacyChecked,
  .fields-container__grid-root--statementLegislationChecked,
  .fields-container__grid-root--statementLegallyCompetentChecked,
  .fields-container__grid-root--statementOwnAccountChecked,
  .fields-container__grid-root--statementNoFraudAmlChecked,
  .fields-container__grid-root--statementIdentificationChecked {
    display: none;
  }
}
