/* Icons */
@font-face {
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter/Glyphter.woff') format('woff');
}
/* Icons custom */
@font-face {
  font-family: 'Glyphter-custom';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter-custom/Glyphter.woff') format('woff');
}
[class*='icon-']:before{
  display: inline-block;
  padding: 4px;
  font-family: 'Glyphter-custom';
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}


@font-face {
  font-display: swap;
  font-family: 'Anuphan';
  font-style: normal;
  font-weight: 400;
  src: url('/cms/fonts/anuphan/anuphan-regular.woff2') format('woff2'),
  url('/cms/fonts/anuphan/anuphan-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Anuphan';
  font-style: normal;
  font-weight: 600;
  src: url('/cms/fonts/anuphan/anuphan-600.woff2') format('woff2'),
  url('/cms/fonts/anuphan/anuphan-600.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Anuphan';
  font-style: normal;
  font-weight: 700;
  src: url('/cms/fonts/anuphan/anuphan-700.woff2') format('woff2'),
  url('/cms/fonts/anuphan/anuphan-700.woff') format('woff');
}

/* Clash Display */
@font-face {
  font-family: 'Clash Display';
  src: url('/cms/fonts/clash-display/a.woff2') format('woff2'),
  url('/cms/fonts/clash-display/a.woff') format('woff'),
  url('/cms/fonts/clash-display/a.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Display';
  src: url('/cms/fonts/clash-display/b.woff2') format('woff2'),
  url('/cms/fonts/clash-display/b.woff') format('woff'),
  url('/cms/fonts/clash-display/b.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Clash Display';
  src: url('/cms/fonts/clash-display/semibold.woff2') format('woff2'),
  url('/cms/fonts/clash-display/semibold.woff') format('woff'),
  url('/cms/fonts/clash-display/semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

