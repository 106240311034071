.sof-validation {
  tbody {
    tr {
      &:nth-child(odd) {
        background: var(--table-even-background);
      }
    }
  }
  th,
  td {
    border-bottom: none;
    color: color(text, main);
  }
  &__status {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__gallery-item {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__gallery-open {
    padding: 5px 7px;
    min-width: 0;
    min-height: 0;
    border-radius: 3px;
    vertical-align: middle;

    &:hover {
      background: none;
    }

    & > .button-label {
      font-size: 14px;
      margin-left: 5px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__upload {
    font-size: 13px;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    color: color(text, main);
    border: 1px solid;
    border-radius: 4px;
    padding: 5px 12px 4px;
    font-weight: normal;
  }
  @include media-sm {
    display: block;

    &__button-reasons {
      grid-area: reasons;
      display: flex;
      align-items: center;
      margin-left: 10%;
    }
    &__gallery-open {
      padding: 0;
      text-align: left;
      & > .button-label {
        margin: 0;
      }
    }
    &__button-reasons.hidden--desktop {
      font-size: 14px;
      width: 100%;
    }
    &__button-reason {
      cursor: pointer;
      text-decoration: underline;
      color: color(error);

      &:hover {
        text-decoration: none;
      }
    }
    .tableHead-root {
      display: none;
    }
    .tableBody-root {
      display: flex;
      flex-wrap: wrap;
      .tableRow-root:not(.table-row--addition) {
        display: grid;
        grid-template:
          "type amount status"
          "date amount status"
          "expiryDate amount status"
          "reasons amount status";
        grid-template-columns: auto minmax(auto, 130px) 20px;
        grid-template-rows: 40px 20px 20px 40px;
        width: 100%;
        height: 120px;
        .tableCell-root:empty {
          display: none;
        }

        &.sof-validation__row--status {
          &--null {
            grid-template-areas:
              "type amount status"
              "type amount status"
              "type amount status"
              "type amount status";
          }
          &--expired,
          &--rejected {
            grid-template-areas:
              "type amount status"
              "date expiryDate status"
              ". expiryDate status"
              "reasons expiryDate status";
          }
        }

        .tableCell-root,
        .table-cell {
          padding: 2px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          .hidden--desktop {
            color: color(text, main);
          }
          &--status {
            grid-area: status;
            writing-mode: vertical-lr;
            text-orientation: mixed;
            color: color(primary, light);
            transform: rotateZ(180deg);

            &--rejected {
              background: color(error);
              & > span {
                text-decoration: none;
              }
            }
            &--expired {
              background: color(expired);
            }
            &--approved {
              background: color(success);
            }
            &--uploaded {
              background: color(disable, dark);
            }
          }
          &--type,
          &--type .button-label {
            grid-area: type;
            font-size: 16px;
          }
          &--amount {
            grid-area: amount;
            width: 100%;
            max-width: 130px;
            margin-left: auto;
            margin-right: 20px;
          }
          &--date {
            grid-area: date;
          }
          &--expiryDate {
            grid-area: expiryDate;
            align-items: flex-start;
            & > .hidden--desktop {
              padding: 1px 0;
            }
          }
          &--type,
          &--date,
          &--expiryDate {
            justify-content: flex-start;
            margin-left: 10%;
            text-align: left;
          }

          .sof-validation__upload {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 100px;
            height: 42px;
            font-size: 14px;
          }
        }
      }
    }
    &__upload {
      font-size: 10px;
      padding: 4px 10px 3px;
    }
    .table-row--addition {
      display: block;
      height: auto;
      width: 100%;
      border-right: solid 20px color(error);

      .table-cell {
        &--rejected-reasons {
          display: block;
          margin-left: calc((100% - 120px) / 10);

          ul {
            padding-left: 20px;
          }
        }
      }
    }
    &--amountRange.sof-validation .tableBody-root .tableRow-root {
      grid-template: "type amount";
      height: 70px;
    }
  }
  .table-cell--rejected-reasons {
    ul {
      margin: 0 0 20px;
      padding: 0 0 0 40px;
    }
  }
  .table-cell--status--rejected > span {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

