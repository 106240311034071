.game-search {
  input {
    width: 200px;
    margin-top: -3px;
    padding: 6px 10px 6px 40px;
    border: 1px solid #9a9a9a;
    background-color: transparent;
    box-shadow: none;

    & + div[role="progressbar"] {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-content: center;
      width: 16px !important;
      height: 100% !important;
      padding-right: 4px;
      box-sizing: content-box;

      svg {
        min-width: 100%;
      }
    }
  }
}
