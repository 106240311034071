.tournaments {
  @mixin tournaments-button {
    text-transform: none;
    word-break: break-all;
    color: color(primary, light);
    background-color: color(primary, dark);

    &.button-primary--active,
    &:hover {
      color: color(primary, dark);
      background-color: color(primary, light);
    }
  }

  &__buttons,
  &__list {
    button {
      @include tournaments-button;
    }

    @include media-sm {
      overflow-y: auto;
      min-height: auto !important;
      display: flex;
      margin-bottom: 0 !important;
    }
  }

  // Buttons
  &__buttons {
    background: #4d4d4d;
    text-align: left;
    padding: 10px 15px;
    &:after {
      content: '';
      min-width: 5px;
    }

    button {
      margin-right: 10px;

      @include media-sm {
        min-width: initial;
        white-space: nowrap;
        padding: 6px 12px;
      }
    }
  }

  // List
  &__list {
    position: absolute;
    left: 0;
    right: 0;
    padding: 10px 15px;
    height: 100%;

    &:after {
      content: '';
      min-width: 10px;
      display: block;
      min-height: 10px;
    }

    .markdown-root {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: left;
      color: color(primary, light);
    }

    &__wrap {
      position: relative;
      background: #444444;
      overflow-x: auto;

      @include media-md {
        width: 100%;
      }
    }

    button {
      flex: 0 0 auto;
      width: 100%;
      margin: 5px 0;
    }

    @include media-sm {
      position: relative;
      display: flex;

      button {
        width: auto;
        min-width: initial;
        margin: 5px;
        white-space: nowrap;
      }
    }

    @include media-sm {
      padding: 10px 12px;

      button {
        padding: 6px 12px;
      }
    }
  }

  // Info
  &__info {
    background: color(primary, main);
    padding: 0 15px;

    h1,
    .markdown-root {
      text-align: left;
    }

    h1 {
      font-size: 30px;
    }
  }

  // Body
  &__body {
    min-height: 285px;
    margin-bottom: 25px;
  }

  // Item
  &__item-content {
    display: flex;
    color: color(primary, light);
    background: #333333;
    padding: 10px 15px 30px;
    text-align: left;

    h3 {
      color: color(primary, main);
    }
  }

  &__description {
    padding-right: 10px;

    @include media-xs {
      padding-right: 0;
    }
  }

  &__time-block {
    padding-bottom: 5px;

    span {
      display: inline-block;
    }
  }

  &__prize-pool {
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__prize-pool-title {
    padding-bottom: 5px;
    padding-right: 5px;
    font-weight: $font-weight-alt;
  }

  &__prize-pool-block {
    @include media-xs {
      text-align: left;
    }
  }

  &__prize-pool-item {
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 5px;
  }

  &__time {
    margin-bottom: 10px;
  }

  &__win-criteria,
  &__participants {
    margin-bottom: 15px;
  }

  &__time-title {
    padding-right: 5px;
    padding-bottom: 5px;
  }

  &__win-criteria-title {
    font-weight: $font-weight-alt;
  }
  &__win-criteria {
    p {
      margin: 0;
    }
  }

  &__participants-title {
    font-weight: $font-weight-alt;
  }

  &__table {
    position: relative;
    margin-top: 20px;
    padding-left: 10px;
    max-height: 89%;
    overflow-x: auto;
    white-space: nowrap;
    box-sizing: border-box;

    @include media-md {
      max-height: 220px;
      padding-left: 0;
    }

    .custom-table-body {
      position: absolute;
      left: 0;
      top: 0;
      background: #6d6d6d;
      display: table;
      width: 100%;

      @include media-md {
        position: relative;
      }
    }

    .custom-table-row--active {
      color: color(primary, main);
    }

    .custom-table-cell {
      border-right: 1px solid #9c9b9b;
      border-bottom: 1px solid #9c9b9b;
    }

    .custom-table-row .custom-table-cell:last-child {
      border-right: 0;
    }
  }

  &__links {
    margin-bottom: 25px;

    a {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  &__opt-in {
    .tournaments__content_loading {
      justify-content: flex-start;
      min-height: 42px; // Same as button height
    }

    .buttons {
      text-align: left;
    }

    .notification-root {
      margin-bottom: 0;
      background: color(primary, dark);

      .notification-message {
        color: color(primary, light);

        a {
          color: color(primary, light);
        }
      }
    }
  }

  // Loading
  &__content_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  // Scroll
  &__scroll {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: color(primary, main);
      outline: 1px solid slategrey;
    }
  }
}
