.post-login-content-wrap--profile-close {
  h2 {
    display: none;
  }
  legend {
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--body-text-dark-color);
  }
  .button-secondary {
    margin: 30px 0 15px;
  }
}
