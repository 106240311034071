.documents-list {
  th,
  td {
    border-bottom: none;
    color: color(text, main);
  }
  &__status {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .table-cell--status--rejected > span {
    cursor: pointer;
  }
  .table-cell--status > span {
    @include media-sm {
      background-color: unset;
    }
  }
  .table-cell--name {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include media-sm {
    display: block;

    &__button-reasons.hidden--desktop {
      margin-top: 10px;
      font-size: 14px;
      width: 100%;
    }
    &__button-reason {
      cursor: pointer;
      text-decoration: underline;
      color: color(error);

      &:hover {
        text-decoration: none;
      }
    }
    .tableHead-root {
      display: none;
    }
    .tableBody-root {
      display: flex;
      flex-wrap: wrap;
      .tableRow-root:not(.table-row--addition) {
        display: grid;
        grid-template:
          "name status"
          "date status"
          "update status"
          "reasons status";
        grid-template-columns: auto 20px;
        grid-template-rows: 40px 20px 20px 40px;
        width: 100%;
        height: 120px;
        .tableCell-root:empty {
          display: none;
        }

        .tableCell-root,
        .table-cell {
          padding: 2px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          .hidden--desktop {
            color: color(text, main);
          }
          &--status {
            grid-area: status;
            writing-mode: vertical-lr;
            text-orientation: mixed;
            color: color(primary, light);
            transform: rotateZ(180deg);

            &--rejected {
              background: color(error);
              & > span {
                text-decoration: none;
              }
            }
            &--expired {
              background: color(expired);
            }
            &--approved {
              background: color(success);
            }
            &--uploaded {
              background: color(disable, dark);
            }
          }
          &--date {
            grid-area: date;
          }
          &--update {
            grid-area: update;
          }
          &--name {
            grid-area: name;
            font-size: 16px;
          }
          &--reasons {
            grid-area: reasons;
            color: color(error);
          }

          &--reasons,
          &--update,
          &--date,
          &--name {
            justify-content: flex-start;
            margin-left: calc((100% - 130px) / 9);
            text-align: left;
          }
        }
      }
    }
    &__upload {
      font-size: 10px;
      padding: 4px 10px 3px;
    }
    .table-row--addition {
      height: auto;
      width: 100%;
      border-right: solid 20px color(error);

      .table-cell {
        &--rejected-reasons {
          display: block;
          margin-left: calc((100% - 20px) / 10);

          ul {
            padding-left: 20px;
          }
        }
      }
    }
  }
  .table-cell--rejected-reasons {
    ul {
      margin: 0 0 20px;
      padding: 0 0 0 40px;
    }
  }
}
