.netherlands-limits-confirm-modal {
  &__notification p {
    font-size: 14px;
  }

  &__confirm-button {
    padding: 12px 32px;
    white-space: break-spaces;
    line-height: 19px;
  }

  &__group-confirm-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 30px;
  }
}

.netherlands-limits-modal {
  &:first-child {
    background: linear-gradient(
      to bottom,
      #000000,
      #541f5a,
      #82226c,
      #e60881
    ) !important;
  }
}

.netherlands-limits-deposit-modal {
  :has(.error-code-228 ) &:first-child,
  :has(.error-code-249 ) &:first-child,
  :has(.error-code-250 ) &:first-child {
    background: linear-gradient(
        to bottom,
        #000000,
        #541f5a,
        #82226c,
        #e60881
    ) !important;
  }
}
