.live-casino {
  background: var(--gradient);

  @include media-to-md {
    padding: 40px 0;
  }
  @include media-md {
    overflow: auto;
    padding: 0;
  }
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 16px 0;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    text-transform: inherit;

    &::before {
      margin-right: 5px;
      color: #FEBE10;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    overflow: auto;

    .picture {
      width: 100%;

      img {
        width: 100%;
        border-radius: 8px;

        @include media-to-md {
          object-fit: cover;
        }
        @include media-md {
          object-fit: contain;
        }
      }
    }
  }
  &__item {
    min-width: 304px;

    &:hover {
      opacity: 0.7;
    }
  }
}
