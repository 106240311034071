.modal-root .modal-contentRoot {
  .incode-verification-form {
    margin: 0 -50px;

    @include media-sm {
      margin: 0 -20px;
    }
  }
}
.modal-root,
.popup-decorator {
  overflow: visible;
  border-radius: 4px;

  &.is-submodal {
    .modal-paper {
      width: 420px;
      margin: 16px;

      .modalContentWrapper {
        .modal-titleRoot {
          padding-top: 25px;
        }

        .modal-contentRoot {
          padding-right: 25px;
          padding-left: 25px;

          .markdown-root {
            text-align: center;
          }
          .table-root .tableBody-root {
            color: color(text, main);
          }
        }
      }
    }
  }

  .modal-buttonRoot {
    @include close-button;
    background-color: unset;
  }
  .modal-rootButtons {
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .modal-rootButton {
    > .button-default {
      min-height: 32px;
      padding: 4px 8px;
      font-size: 16px;
      text-decoration: none;

      &:after {
        display: none;
      }
      > .button-label {
        white-space: nowrap;

        &::before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: color(secondary, main);
          transition: all $main-transition-time;
        }
        &:hover::before {
          opacity: 0;
        }
      }
    }
  }
  .modal-paper {
    width: 640px;
    max-width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: var(--modal-background);

    .modalContentWrapper {
      display: flex;
      flex-direction: column;
      border-radius: inherit;
      overflow-y: auto; // [FIN-21054] - wrong style in FireFox

      .modal-titleRoot {
        padding: 30px 25px 10px;
        background: var(--modal-background);

        h2 {
          text-align: center;
          text-transform: none;
          font-size: 24px;
          font-weight: 700;
          color: var(--modal-title-color);
        }
        & + .modal-contentRoot {
          padding-top: 0;

          h1 {
            margin-top: 10px;
          }
        }
      }

      .modal-contentRoot,
      .popup-decorator__content {
        color: var(--modal-text-color);
        border: 0;
        border-radius: 4px;
      }
      .modal-contentRoot {
        overscroll-behavior: none;

        @include media-to-md {
          padding: 3px 24px 0 24px;
        }
        @include media-md {
          padding: 16px;
        }
      }
      .modal-contentRoot > div:last-child {
        margin-bottom: 45px;

        &.notification-root {
          // if you remove it notifications in modals cannot use margin (a bug of browser, at last Chrome)
          animation: none;
        }

        @include media-sm {
          padding-bottom: 15px;
        }
      }

      .modal-contentRoot > div:not(.notification-root):not(.grid-root):not(.deposit-form):not(.withdrawal-form):not(.qrcode-form):not(.resume-form):not(.verification-form):not(.game-search):not(.depositErrorMethodsModal__limit-header) {
        display: block;
        height: 100%;

        @include isMozillaFirefox {
          height: auto;
        }

        @include isMicrosoftEdge {
          height: auto;
        }
      }
      .field-root {
        padding-right: 0;
      }
    }
  }
  .content__wrapper {
    width: 100%;

    .group-root {
      width: 100%;
    }
  }
}
.page-modal--id-terms-conditions,
.page-modal--id-privacy-policy,
.page-modal--id-responsible-gambling,
.page-modal--id-bonus-terms {
  .modal-paper {
    width: 1168px;
  }
  .underlined-title {
    display: none;
  }
}
.modal__gambling-message {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
  margin: 20px 0;
  padding-bottom: 0 !important; // override: .modal-root .modal-paper .modalContentWrapper .modal-contentRoot > div:last-child `padding-bottom`
  text-align: center;

  @include media-sm {
    text-align: left;
  }

  img {
    @include size(32px);
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 8px;
    filter: brightness(0) grayscale(100%);
  }

  p {
    margin: 0;
    font-size: 14px;
    color: color(text, light);
  }
}
.confirmation-modal__buttons {
  margin-top: 20px;
  justify-content: center;
  gap: 15px;

  .gridItem-root {
    max-width: initial;
    flex-basis: auto;
  }
  .button-root {
    margin: 0 10px;
  }
}

/* QRcode modal */
.qrcode-modal {
  .modal-paper {
    width: 440px;
  }

  .qrcode-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__code {
      width: 250px;
      height: 250px;
      border: 1px solid black;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .button-root {
    p {
      margin: 0;
    }

    strong {
      font-weight: $font-weight-base;
    }
  }
}


/* Cancel modal */
.cancel-confirmation-modal {
  text-align: center;
  .modal-paper {
    width: 420px;
  }
}

/* FreeSpins Finished modal */

.free-spins-finished-modal {
  &__buttons {
    text-align: center;

    button,
    button + button {
      margin: 0 7.5px;
    }
    @include media-xs {
      button + button {
        margin-top: 15px;
      }
    }
  }
}

.campaigns__modal{
  .markdown-root{
    text-align: center;
  }
}

@include media-sm {
  .modal-root {
    .modal-contentRoot {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 15px;
    }
    .modal-paper {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      justify-content: center;
    }
  }
}

.custom-modal {
  @include media-to-md {
    --indent: 0 22px;
  }
  @include media-md {
    --indent: 0 16px;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }
  &__title {
    margin: 0 0 8px 0;
    padding: var(--indent);
    font-family: $font-accent;
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.04em;
  }
  &__description {
    padding: var(--indent);
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: #a2a2a2;
  }
  &__form {
    margin-top: 15px;
    padding: var(--indent);
  }
  &__image {
    @include media-to-md {
      margin-bottom: 48px;
    }
    @include media-md {
      margin-bottom: 30px;
    }
    img {
      max-width: 592px;
      width: 100%;
      @media (max-width: $size-sm) {
        max-width: 100%;
      }
    }
  }
  &__button {
    width: 100%;

    @include media-to-md {
      margin-top: 27px;
      margin-bottom: 10px;
    }
    @include media-md {
      margin-top: 18px;
      margin-bottom: 0;
    }
  }
  &__info-after-button {
    p,
    a {
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
    }
    p {
      text-align: center;
      color: #a2a2a2;
    }
  }
  .modal-paper {
    @include media-md {
      height: auto;
      margin-top: auto;
    }
  }
  .center-content {
    @include media-to-md {
      min-height: 313px;
    }
    @include media-md {
      min-height: 286px;
    }
    .button-root {
      width: 100%;
    }
  }
}
.payment-modal {
  .modal-paper .modalContentWrapper {
    .modal-titleRoot {
      padding-top: 22px;
      padding-bottom: 25px;

      h2 {
        padding-bottom: 24px;
        font-family: $font-primary;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
  .modal-rootButtons {
    top: 12px;
    right: 12px;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 5px;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
  }
}
.cumulative-deposit-limit-setting-modal,
.max-balance-limit-setting-modal,
.session-time-limit-setting-modal,
.depositErrorMethodsModal__welcome-modal {
    .modalContentWrapper {
      height: 100%;
    }
    .modal-paper {
      @media (max-width: $size-sm) {
        justify-content: flex-start;
      }
    }
}

/* Gambling Contract Modal */

.gambling-contract-modal {
  &__error {
    color: color(error)
  }
}
