.featured-games-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__slide {
    &.game-thumbnail {
      width: 100%;
      flex-shrink: 0;
    }
  }

  &__pagination {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    .swiper-pagination-bullet {
      position: relative;
      width: 20px;
      height: 20px;

      &:not(.swiper-pagination-bullet-active) {
        cursor: pointer;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border: 1px solid color(primary, light);
        border-radius: 50%;
        content: "";
        transition: background 200ms;
      }

      &:hover,
      &-active {
        &::before {
          background: color(primary, light);
        }
      }
    }
  }
}
