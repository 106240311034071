.sweden-responsible-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__icon {
    margin: 0 5px;
    vertical-align: middle;
  }
  &__button {
    display: inline-block;
    width: 115px;
    min-width: 0;
    min-height: 0;
    margin: 3px;
    padding: 0;
    line-height: 26px;
    font-family: Verdana, sans-serif;
    font-size: 12px;
    white-space: nowrap;
    text-decoration: none;
    text-align: left;
    color: #000;
    font-weight: $font-weight-base;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid #000;
    background: #fff;

    &:hover {
      color: #000;
      background: #fff;
    }
  }
}
