.tooltip-root {
  z-index: 2;
}
.session-time {
  display: flex;
  align-items: center;

  &--has--icon {
    .session-time__title {
      margin-left: 4px;
    }
  }
  &-limit-modal__button--logout {
    margin-top: 20px;
  }
}
