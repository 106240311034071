.pending-rounds {
  margin: 32px auto;
}

.pending-rounds-device {
  padding: 10px 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: rgba(color(primary, dark), 0.5);

  &__icon {
    margin-top: 20px;
    vertical-align: top;
  }
}
