.panel {
  width: 100%;
  margin: auto;
  @include media-sm {
    &:not(&--full) {
      padding: 15px 30px;
    }
  }
  @media (min-width: $size-sm) {
    max-width: 66.6667%;
  }
}
