.verify-via-mobile-or-smart-id-form {
  .user-identification {
    text-align: center;
    &__form-fields {
      text-align: left;
    }
  }
  &__ask-to-login,
  &__success {
    text-align: center;
  }
  &__logging-in {
    text-align: center;
    &-text {
      margin-top: 10px;
    }
  }
  .fields-container {
    justify-content: space-between;
    margin-top: 20px;

    @media (min-width: 768px) {
      &__grid-root {
        margin: 0 -10px;
        padding: 0 10px;
      }
    }
  }
  .mobile-smart-id-verification-form__buttons {
    display: flex;
    justify-content: center;
    margin: 20px auto 0;

    @include media-sm {
      margin-bottom: 20px;
    }
    button + button {
      margin-left: 30px;
    }
  }
}
