.not-found-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -$header-expanded-height;
  background-image: url("/cms/img/banners/banner-bg.png");
  background-repeat: repeat-x;

  @include media-md {
    margin-top: -$header-collapsed-height;
  }
  &__banner {
    padding-top: $header-expanded-height;

    @include media-md {
      padding-top: $header-collapsed-height;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 13px;
    max-width: 800px;
    width: 100%;
    height: 100%;
    margin: 80px auto 85px;
  }
  &__subTitle {
    margin: 0;
    font-family: $font-primary;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: unset;
    text-align: center;
    color: #FEBE10;
  }
  &__title,
  &__title p,
  &__title strong {
    margin: 0;
    font-family: $font-accent;
    text-transform: uppercase;
    text-align: center;
    color: #fff;

    strong {
      color: #FEBE10;
    }

    @include media-to-md {
      font-size: 48px;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 0.04em;
    }
    @include media-md {
      font-size: 32px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.04em;
    }
  }
  &__text {
    max-width: 500px;
    margin: 0 auto;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 8px;
  }
  .game-list {
    margin-bottom: 50px;
  }
  .recommended-games {
    padding-bottom: 0;
  }
}
