.post-login-content-wrap--limits {
  .button-root {
    display: flex;
    justify-content: left;
  }
}
.post-login-content-wrap--limits-responsible-gambling .limits__title {
  display: none;
}
.limits {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
  }
  &__item {
    flex-basis: 0;

    &--payments {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }

    &:empty {
      display: none;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
  }
  &__item:last-child {
    flex-grow: 1;
  }
}
.limit-form {
  &-netDeposit {
    gap: 20px;
  }
  &__accordion {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 24px;

    &--maxBalance, &--netDeposit {
      .limit-amount {
        &__amount {
          &-input {
            .input-before {
              height: 44px;
              padding: 0;
            }
            input {
              height: 44px;
            }
          }
        }
      }
    }

    &--netDeposit {
      .amount-message {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        margin: 12px 0;

        &--date {
          min-height: 1em;
          margin-top: 8px;
          line-height: 1em;
          font-family: "Roboto", sans-serif;
          font-size: 0.75rem;
          text-align: left;
          color: #A0A0A0;
        }
      }
    }

    h6 {
      font-size: 16px;
      font-weight: 600;
      font-family: 'Clash Display', sans-serif;
      text-transform: uppercase;
      margin: 0;
    }

    .accordion {

      &-panel {
        border-radius: 8px;
        background: none;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: none;

        &--expanded {
          margin: 0;
          .accordion-summary > div:after {
            transform: rotate(-90deg);
          }
        }
      }
      &-summary {
        text-transform: capitalize;

        &--content-expanded {
          min-height: 48px;
        }

        &--content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 12px 0;

          &--active {
            strong {
              color: var(--color-blue);
            }
          }

          &--inactive {
            strong {
              color: #EE756E;
            }
          }

          .markdown-root {
            p {
              margin: 0;
              text-align: right;
            }
            strong {
              display: block;
              font-weight: normal;
              text-transform: capitalize;
            }
          }
        }

        > div {
          &:after {
            content: '\004e';
            font-family: 'Glyphter-custom';
            position: absolute;
            right: 20px;
            top: calc(50% - 8px);
            transform: rotate(90deg);
          }
        }
      }
      &-details {
        padding: 0;
        flex-direction: column;

        .limit-amount {
          width: 100%;
          padding: 0 24px 24px 24px
        }

        .limit-form__buttons {
          padding: 0 24px 24px 24px;

          button {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
  .deposit-form &,
  .cumulative-deposit-limits-modal & {
    margin-bottom: 0;
    padding: 10px 0;
  }
  &__net-deposit {
    height: 100%;
    margin-bottom: 0;

    .limit-form__item {
      padding-top: 0px;
    }
  }
  .deposit-form &,
  .cumulative-deposit-limits-modal &,
  .game-decorator__error-content--limits &,
  .game-decorator__error-content--maxBalanceLimit &,
  .game-decorator__error-content--sessionTimeLimit &,
  .max-balance-limit-setting-modal &,
  .session-time-limit-setting-modal &,
  .cumulative-deposit-limit-setting-modal & {
    .button-secondary {
      margin-top: 20px;
    }
  }
  &--space {
    margin-bottom: 0;
  }
  &__title {
    display: none;
    margin: 0 0 15px 0;
  }
  &__item {
    margin: 35px 0 10px;

    &--amount {
      padding-right: 40px;

      @include media-sm {
        margin: 0;
        padding: 0;
      }
    }
    &--period {
      padding-left: 40px;

      @include media-sm {
        padding-left: 0;
      }
    }
    &--date {
      align-self: flex-start;
    }
  }
  &__field legend {
    margin-bottom: 10px;
  }
  &__radiogroup {
    @include media-xs {
      flex-direction: column;

      label {
        margin-bottom: 10px;

        + label {
          margin-left: 0;
        }
      }
    }
  }
  & &__radio {
    white-space: normal;

    @include media-xs {
      margin-bottom: 8px;
    }
    .formControlLabel-label {
      text-transform: uppercase;
    }
  }
  &__checkbox-wrapper {
    align-self: flex-end;
    margin-left: auto;
  }
  &__checkbox {
    margin-top: 15px;
    padding-left: 40px;

    @include media-sm {
      padding-left: 0;
    }
    .checkbox-checked {
      border-color: color(primary, main);
      background-color: color(primary, main);

      &::before {
        color: var(--body-text-dark-color);
      }
    }
  }
  &__spacer {
    display: flex;
    gap: 24px;

    &--collapsible {
      gap: 0;
      .limit-form__buttons {
        margin-top: 24px;
        @media (max-width: $size-sm) {
          position: fixed;
          bottom: 16px;
          left: 16px;
          right: 16px;
        }
      }
      .collapsible__content {
        display: flex;
        flex-direction: column;
        gap: 24px
      }
      .limit-form__collapsible-title {
        font-size: 16px;
      }
    }

    .limit-form__item {
      margin: 0;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  &__notifications {
    text-align: left;

    .notification-root {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }
  &__showMore {
    text-align: center;
    cursor: pointer;
  }
}
