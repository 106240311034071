.activity-form {
  &__cell-title {
    display: none;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: color(text, main);
  }
  .custom-table-cell {
    &--status {
      padding: 0;

      .activity-form__cell-title {
        @include media-md {
          text-align: center;
        }
      }
      .activity-form__cell-title + span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 3px 3px 17px;
        border-radius: 16px;
        font-family: $font-primary;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #fff;

        @include media-to-md {
          &::before {
            content: "";
            position: absolute;
            left: 10px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
        @include media-md {
          padding: 0;
        }
      }
    }
  }
  .custom-table-cell--status--login  {
    .activity-form__cell-title + span {
      background-color: color(success);

      &::before {
        background-color: rgba(0, 243, 199, 1);
      }
    }
  }
  .custom-table-cell--status--logout {
    .activity-form__cell-title + span {
      background-color: color(error);

      &::before {
        background-color: rgba(243, 0, 44, 1);
      }
    }
  }
  @media (max-width: 668px) {
    padding: 0;

    .custom-table {
      display: block;
    }
    .custom-table-cell {
      border: none;
    }
    &__item,
    &__count,
    & > .grid-root:first-child {
      padding: 0 20px;
      background: var(--content-background);
    }
    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border-bottom: 1px solid color(primary, dark);

      .custom-table-cell {
        flex: 1;
        white-space: nowrap;
        padding: 15px;

        &:first-child {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    &__cell-title {
      display: block;
    }
    &__item {
      margin: 10px 0;
    }
  }
}
.post-login-content-wrap--activity {
  @media (max-width: 668px) {
    background: none;

    .post-login__title {
      background: var(--activity-form-post-login-title);
      margin: 0;
      padding: 20px 0;
    }
  }
}
