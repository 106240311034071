.description {
  text-align: justify;

  .subTitle {
    margin: 23px 0 0;
    font-family: $font-accent;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
  }
  p {
    margin: 0 0 23px;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
  }
  ul {
    padding-left: 15px;
  }
  li {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
  }
}
