.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 25;
  opacity: 1;

  &:not(#root[data-url*='/my/'] &):not(#root[data-url*='/all-games'] &):not(#root[data-url*='/slots-games'] &)  {
    background: linear-gradient(180deg, rgba(27, 7, 66, 0.8) 0%, rgba(27, 7, 66, 0) 100%);
  }
  @include media-to-md {
    height: $header-expanded-height;
  }
  @include media-md {
    height: $header-collapsed-height;
  }
  &__icon {
    padding: 11px;
  }
  &__time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #7d797d;
    background-color: #242125;

    @include media-to-md {
      padding: 8px 24px;
    }
    @include media-md {
      padding: 8px 16px;
    }
    &--prelogin {
      justify-content: space-between;
    }
    &__item {
      display: flex;
      gap: 16px;
    }
  }
  &__container {
    display: flex;
    max-width: none;
    height: 100%;
    margin: auto;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @include media-md {
      align-items: baseline;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
  &__group-left,
  &__group-center,
  &__group-right {
    display: flex;
    flex: 1;
    transition: $main-transition-time;
  }
  &__group-left {
    gap: 16px;
  }
  &__group-right {
    gap: 8px;
  }
  &__group-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__group-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }
  &__group-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;

    & > * {
      width: 100%;
    }
  }
  &__logo {
    display: block;
    transition: $main-transition-time;
    margin: auto 0;

    img {
      color: inherit;
      display: block;
      width: 100%;
      max-height: 35px;
    }
  }
  & &__menu {
    padding: 12px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px;
  }
  & &__balance {
    position: relative;
    min-width: 166px;
    padding: 12px;
    border: none;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;

    &::before {
      color: #FEBE10;
    }
    &::after {
      @include svg-icon(custom);
      content: '\0048';
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      color: #3A1979;
      background-color: #00F3C7;
    }
    .button-label {
      justify-content: flex-start;
      padding-right: 48px;
      padding-left: 8px;
    }
  }
  &__game-button {
    min-height: 25px;
    padding: 0 9px;

    &::before,
    &::after {
      @include svg-icon(custom);
    }
    &--deposit {
      &::before {
        content: '\0047';
        font-size: 14px;
      }
      &::after {
        content: '\0048';
        margin-left: 5px;
        font-size: 10px;
      }
    }
    &--home {
      &::before {
        content: '\002c';
      }
    }
  }
  &__ksa-logo {
    width: 73px;
    height: 23px;
    opacity: 40%;
  }
}
