.promotion-card {
  display: flex;
  padding: 23px 26px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__image {
    margin-right: 24px;
    width: 120px;
    height: 160px;

    img {
      display: flex;
      width: 120px;
      height: 160px;
      max-width: initial;
    }
  }
  &__title {
    margin: 7px 0 0 0;
    font-family: $font-accent;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #fff;
  }
  &__claim-btn {
    display: block;
    max-width: 220px;
    width: 100%;
    min-height: 38px;
    margin-top: auto;
    border-radius: 24px;
    font-family: $font-accent;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.04em;
    text-align: center;
    color: #3A1979;
    text-decoration: none;
    background-color: #00F3C7;

    p {
      font-family: $font-accent;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.04em;
      text-align: center;
      color: #3A1979;
    }
  }
  &__content {
    margin-top: 7px;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
  }
  p {
    margin: 0;
  }
}
