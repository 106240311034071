.collapsible {
    width: 100%;
    padding: 0;
    box-shadow: none;
    background: none;
    &.details-expanded {
        margin: 0;
        .collapsible__expandIcon {
            transform: rotate(180deg);
            &::after {
                color: white;
              }
        }
        .collapsible__summary {
          color: white
        }
    }
    .detailsSummary-root {
        padding: 0;
    }
    .detailsSummary-expandIcon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__summary {
        padding: 0;
        color: rgba(255, 255, 255, 0.4);
    }
    &__content {
        padding: 0;
        display: block;
    }
    &__expandIcon {
        transition: $main-transition-time;
        &::after {
            @include svg-icon(custom);
            content: "\004e";
            color: rgba(255, 255, 255, 0.4);
            font-size: 14px;
            transform: rotate(90deg);
          }
    }
}
