.limit-amount {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 24px;

  &__icon-select {
    &::after {
      @include svg-icon(custom);
      z-index: -1;
      content: "\004e";
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotate(90deg);
      color: #fff;
    }
  }
  &__title {
    text-transform: uppercase;
  }
  &__title-content {
    font-family: $font-accent;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  &__amount-input {
    align-items: center;
    .input-before {
      font-size: .8rem;
    }
    &.input-disabled > .input-before,
    input.input-inputDisabled {
      color: color(label, disabled);
    }
  }
  .limit-amount__amount-input {
    border: 1px solid #454545;
    border-radius: 8px;

    input {
      border: none;
    }
  }
  &__period-select {
    text-align: left;
  }
  &__period-select ~ div {
    margin: 0;
  }
  &__amount-field {
    margin: 15px 0 0;

    &.field-error {
      .limit-amount__amount-input {
        border-color: color(errorAccent);
      }
    }

    .field-helperHint {
      p {
        margin: 0;
        line-height: 1;
      }
    }
  }
  &__progress {
    display: flex;
    flex-direction: column;
    font-weight: $font-weight-alt;
    background: var(--limit-amount-background);

    &.unlimited {
      font-size: 24px;
      font-weight: $font-weight-base;
    }
    .circularProgress-back {
      stroke: color(limit-amount, progress-back);
    }
    .circularProgress-path {
      stroke: color(limit-amount, progress-path);
    }
    .available-limit-amount {
      &__title {
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0;
        text-align: right;

        &--available {
          text-align: left;
        }
      }
      &__item {
        display: flex;
        flex-direction: column;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0;
      }
      &__content {
        display: flex;
        flex-direction: row;
        order: 2;
        justify-content: space-between;
        margin-top: 8px;
      }
    }
  }
  &__revert-button,
  &__remove-button {
    border-radius: 50%;
    min-height: 26px;
    min-width: 26px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    &:not(.button-loading) .button-label:before {
      @include svg-icon;
    }
  }
  &__revert-button .button-label:before {
    content: "\007d";
  }
  &__remove-button .button-label:before {
    content: "|";
  }
  &__confirm {
    width: 100%;
    font-size: 12px;
    font-weight: $font-weight-base;
    padding: 7px 5px;
    min-height: 0;
    margin-top: 6px;
    text-transform: none;
    min-width: 50px;
  }
  .linearProgress-progressLine {
    height: 24px;
    border-radius: 4px;
  }
  .field-root .field-helper {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
