@mixin button-accent($color: color(primary, main), $background: var(--color-blue)) {
  color: $color;
  background-color: $background;

  &:hover {
    color: #481e94;
    background-color: #01d3ad;
  }
}
@mixin button-primary($color: #fff, $background: rgba(255, 255, 255, 0.1)) {
  color: $color;
  background-color: $background;
}
@mixin button-secondary($color: #D7C2FF, $background: #0F0F0F) {
  border: 2px solid #24183B;
  color: $color;
  background-color: $background;
}
@mixin button-cta($color: rgba(0, 0, 0, 1), $background: rgba(254, 190, 16, 1)) {
  color: $color;
  background-color: $background;

  &:hover {
    color: $color;
    background-color: rgb(204, 154, 15);
  }
}
@mixin button-link($background: transparent, $color: color(accent, main)) {
  min-width: 0;
  min-height: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  color: $color;
  background-color: $background;
}

@mixin button-root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  min-height: 38px;
  padding: 17px 24px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 24px;
  font-family: $font-accent;
  color: var(--body-text-dark-color);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 14px;
  letter-spacing: 0.04em;
  transition: all $main-transition-time;

    &:hover {
      filter: contrast(0.8) saturate(0.8);
    }
  .button-label {
    font-family: $font-accent;
  }
  &.button-loading {
    color: transparent;
  }
}
.button-root {
  @include button-root;
}
.button-accent {
  @include button-accent;
}
.button-primary {
  @include button-primary;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.button-secondary {
  @include button-primary;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.button-default {
  @include button-primary;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.button-qrcode {
  padding: 0;
  min-width: 42px;
  height: 42px;

  span {
    width: inherit;
    height: inherit;
  }

  img {
    width: inherit;
    height: inherit;
  }

  & + .button-root {
    min-width: unset;
  }
}
.button-link {
  @include button-link;
}
.button-cta {
  @include button-cta;
}
.button-danger {
  @include button-link($color: color(error));
}
.button-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.group-root--buttons {
  margin-top: 15px;
  margin-bottom: 15px;

  button + button {
    margin-left: 15px;
  }
}
.group-root--password-buttons {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .button-root {
    margin: 0;
  }
}
