// game decorator window
.gd-window {
  position: unset;
  margin: auto;
  padding: 10px 40px 15px 20px;
  transition: .2s;

  .desktop & {
    padding-right: calc(40px + var(--scrollbarWidth, 0px));
  }

  &__body {
    position: relative;
    perspective: 900px;

    &.game-decorator-info-panel-opened {
      .gd-window__game {
        transform: rotateY(13deg);
      }
    }
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
  }
  &__title,
  &__local-time {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--gd-text);
  }
  &__title,
  &__title-counterweight {
    body:fullscreen &,
    body:-webkit-full-screen & {
      flex: 1;
    }
  }
  &__title {
    display: flex;
    gap: 18px;
    margin: 0;
    font-family: $font-accent;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #fff;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 7px;
    }
  }
  &__providerTitle {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
  }
  &__providerImg {
    width: 86px;
    height: 64px;

    .image-content {
      display: none;
    }
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  &__providerBg {
    position: absolute;
    inset: 0;
    z-index: -1;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(22, 22, 22, 0) 66.47%, #161616 100%),
      linear-gradient(0deg, rgba(22, 22, 22, 0.8), rgba(22, 22, 22, 0.8));
      z-index: -1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(20px);
      z-index: -1;
    }
  }
  &__local-time,
  &__title-counterweight {
    body:not(:fullscreen) &,
    body:not(:-webkit-full-screen) & {
      display: none;
    }
  }
  &__local-time {
    margin: 0 5px;
  }
  &__game {
    transform-origin: left;
    transition: all 0.3s;

    .prefill { // ?????
      position: relative;
      display: flex;
      flex-wrap: wrap;
      max-width: 60vw;
      min-height: 60vh;
      padding: 40px;
      align-items: center;
      z-index: 50;
      background: #fff;

      .prefill__description {
        color: color(text, light)
      }
      .button-root {
        margin-top: 10px;
      }
    }
  }
  &__footer {
    .game-provider-license {
      max-height: 32px;
      margin: 6px 0 -6px;
      text-align: center;
      transition: $main-transition-time;

      p {
        margin: 0;
        font-size: 12px;
        color: #5a5c5e;
        text-align: center;
        transition: all 0.4s ease;

        img {
          max-width: 100px;
          vertical-align: middle;
        }
      }
      &--hidden {
        max-height: 0;

        p {
          opacity: 0;
        }
      }
    }
  }
  &__main-section {
    width: 100%;

    .aspectRatio-root {
      z-index: 5;
      transition: 300ms ease;

      .aspectRatio-content {
        iframe.game__content {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
    .prefill { // ?????
      position: relative;
      display: flex;
      flex-wrap: wrap;
      max-width: 60vw;
      min-height: 60vh;
      padding: 40px;
      align-items: center;
      z-index: 50;

      .prefill__description {
        color: color(text, light)
      }
      .button-root {
        margin-top: 10px;
      }
    }
  }
  &__info-section {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: all 0.3s;
    background: #fff;

    &.show {
      width: 260px;
    }
  }
  &__info-section-content {
    max-height: 100%;
    overflow-y: auto;
    width: 260px;
    min-width: 260px;
    padding: 10px 10px 10px 20px;

    p {
      margin: 0;
      font-size: 14px;

      strong {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }
  &__controls-section {
    position: absolute;
    top: -70px;
    right: 0;
    display: flex;
    gap: 10px;
  }
  &__control-btn {
    padding: 5px;
    border: 0;
    min-width: 0;
    min-height: 38px;
    display: block;

    .button-label {
      position: absolute;
      right: calc(100% + 60px);
      top: 3px;
      width: auto;
      padding: 5px 10px;
      text-align: center;
      color: #fff;
      background-color: color(secondary, main);
      opacity: 0;
      white-space: nowrap;
      z-index: 5;
      transform: translate3d(40px, 0, 0);
      transition: 300ms ease;
      pointer-events: none;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 0 13px 10px;
        border-color: transparent transparent transparent color(secondary, main);
        transform: translateZ(0);
      }
    }
    &:hover {
      background: transparent;

      .button-label {
        transform: translate3d(50px, 0, 0);
        opacity: 1;
        pointer-events: auto;
      }
    }
    &::before {
      @include svg-icon;
      color: rgba(255, 255, 255, 0.4);
      font-size: 19px;
    }
    &--close {
      position: relative;
      left: 1px;
      top: 1px;

      &::before {
        content: '\004f';
        font-size: 17px;
      }
    }
    &--full-screen {
      &::before {
        content: '\006c';
      }
    }
    &.fullScreenButton-cancel {
      &::before {
        content: '\006b';
      }
    }
    &--favourite-off {
      &::before {
        @include svg-icon(custom);
        content: '\004f';
        font-size: 20px;
      }
    }
    &--favourite-on {
      &::before {
        content: '\0058';
        font-size: 20px;
      }
    }
    &--info-on, &--info-off {
      transition: all 0.4s;
      &::before {
        content: '6';
        border-radius: 50%;
        line-height: 22px;
        font-size: 14px;
        height: 24px;
        width: 24px;
      }
    }
    &--info-on {
      &::before {
        background: #ffcc00;
      }
    }
    &--info-off {
      &::before {
        background: #ebebeb;
      }
    }
  }
}
