.withdrawal-form {
  &__title {
    &--amount {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 10px;

      p {
        margin-top: 25px;
        margin-bottom: 0;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: right;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 24px 0 0;
    font-size: 16px;

    p, strong {
      margin: 0;
      font-size: inherit;
      line-height: normal;
    }
    p {
      margin-right: 8px;
    }
    @include media-xs {
      font-size: 14px;
    }
  }
  &__fields {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);

    .input-input {
      border: none;
    }
    .gridItem-root[class*='withdrawal-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;
    }
  }
  &__field--ibanAccount {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: -25%;
      transform: translateY(100%);
      height: calc(100% - 48px);
      width: 1px;
      background-color: rgba(255, 255, 255, 0.1);;
    }
  }
  &__field--ibanAccount,
  &__field--ibanAccountOwnerName {
    label {
      position: unset;
      margin-top: -10px;
      margin-left: 25px;
    }
    label + .input-root {
      margin: -5px 0 0 0;

      input {
        height: 40px;
        margin-top: 0;
        margin-left: 25px;
        padding: 0 !important;
      }
    }
  }
  &__amount {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:not(&.field-error) {
      margin-bottom: 12px;
    }
    .input-values {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(130px,1fr));
      grid-gap: 10px;
      margin-bottom: 10px;

      &:empty {
        display: none;
      }
    }
    .input-value button {
      width: 100%;
      padding: 12px 16px;
      border-radius: 8px;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      text-align: center;
      color: rgba(255, 255, 255, 0.4);
      background: rgba(255, 255, 255, 0.1);
      transition: $main-transition-time;

      &:hover {
        color: rgba(22, 22, 22, 1);
        background-color: #fff;
      }
      .currency-symbol {
        font-size: inherit;
      }
    }
    .input-selectedValue {
      button {
        color: rgba(22, 22, 22, 1);
        background-color: #fff;

        &:hover {
          color: rgba(22, 22, 22, 1);
          background-color: #fff;
        }
      }
    }
    .input-underline + .button-root {
      height: 48px;
      width: 100%;
    }
    .input-values + .input-underline {
      width: 100%;
      margin-right: auto;

      &:before,
      &:after {
        content: none;
      }
      @include media-xs {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
    .input-values + .input-underline .input-inputSingleline {
      height: 55px;
    }
    .field-helperError {
      margin-top: 6px;
    }
  }
}
.withdrawal-summary {
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &__deduct,
  &__receive {

    @include media-xs {
      max-width: 100%;
    }
    .group-root {
      p {
        margin: 0;
        line-height: 2;
        border-bottom: 1px solid #ccc;
      }
      strong {
        float: right;
      }
    }
    .exchange {
      display: block;
      text-align: right;
    }
  }
  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: color(text, light);

    @include media-xs {
      margin: 10px 0 20px;
    }
    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: color(warning);
    }
  }
  &__notification {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;

    p {
      font-family: $font-primary;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0;
    }
  }
  .notification-root {
    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  & + .button-root {
    display: flex;
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
}

/** Bank account */
.withdrawal-form--1001 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--bankAccount,
      &--bankName,
      &--bankAccountOwnerName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** IBAN */
.withdrawal-form--1011 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--ibanAccountOwnerName,
      &--address,
      &--ibanAccount,
      &--ibanBankName,
      &--bankLocation,
      &--bankCity,
      &--beneficiaryCity,
      &--beneficiaryCountry,
      &--beneficiaryState,
      &--beneficiaryZipCode,
      &--beneficiaryStreet,
      &--beneficiaryHouseNumber,
      &--beneficiaryAddress,
      &--ibanBSB,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

.withdrawal-form__frame {
  width: 100%;
  height: 666px;
  border: none;

  &.loading {
    height: 0 !important;
    min-height: 0 !important;
  }
  & + .withdrawal-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
  &.loading + .withdrawal-form__circular-progress {
    display: block;
  }
}
.modal-root {
  .modal-paper {
    .modalContentWrapper {
      .modal-contentRoot {
        .withdrawal-form__back {
          position: absolute;
          top: 40px;
          left: 80px;
          display: flex;
          min-width: 0;
          min-height: 0;
          padding: 0;
          font-size: 14px;
          border: 0;
          background: none;
          overflow: hidden;
          transition: all 0.2s;

          .button-label {
            transform: translateY(-100%);
            transition: transform .2s;
          }
          &::before {
            @include svg-icon;
            content: '\0062';
            margin-right: 5px;
            font-size: 15px;
            transform: rotate(180deg);
          }
          &:hover {
            color: color(secondary, main);

            .button-label {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}

.revoke-confirmation-modal {
  .modal-paper {
    width: 550px;
  }
}
