.popup-decorator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  &__close-button {
    @include close-button;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
