[data-url="/bingo"] {
  @include media-md {
    .header,
    .main-decorator__footer {
      display: none;
    }
    .main-decorator__content {
      margin: 0;
    }
    .main-decorator {
      margin: 0;
    }
  }
  .header__balance,
  .header__deposit {
    display: none;
  }
}

.bingo {
  &-frame {
    display: block;
    width: 100%;
    height: calc(100vh - #{$header-expanded-height});
    max-width: 1345px;
    margin: auto auto -40px;
    border: none;
    transition: all 0.3s;

    @include media-md {
      height: calc(var(--vh, 1vh)*100);
      margin: 0;
    }

    &--full-width {
      max-width: none;
    }
  }
  &-progress {
    margin: 20px 0;
  }
  &-notification-wrapper {
    margin-top: 40px;

    @include media-sm {
      .gridItem-root {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  .prefill {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 60vw;
    min-height: 60vh;
    margin: auto;
    padding: 40px;
    background: var(--content-background);

    @include media-md {
      max-width: 100vw;
    }
    &__description {
      color: color(text, light);
    }
    .button-root {
      margin-top: 10px;
    }
  }
}
