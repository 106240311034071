.protection {
  @extend %comingSoon;

  &__logo {
    @extend %comingSoon__logo;
  }
  &__content {
    @extend %comingSoon__content;
  }
  &__title {
    @extend %comingSoon__title;
    margin: auto;
  }
  &__text {
    @extend %comingSoon__text;
  }
  &__hero {
    @extend %comingSoon__hero;
  }
  &__button {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 50px;
    cursor: pointer;
    opacity: 0;
    transition: 3s;
    @extend %mobileFilter;

    &:hover {
      opacity: 1;
    }
  }
  &__login {
    background-blend-mode: multiply;

    @include media-to-md {
      background-color: rgba(0, 0, 0, 0.75);
    }
    @include media-md {
      background-color: #000;
    }
  }
}

.protection-page,
.comingSoon__form,
.comingSoon__form .modal-paper,
.protection__button {
  z-index: 1000000; // Hiding the live chat on the protection page
}
