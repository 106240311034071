.welcome-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @include media-to-md {
    margin-top: 17px;
    margin-bottom: 50px;
    padding-bottom: 20px;
  }
  @include media-md {
    margin-bottom: 33px;
    padding-bottom: 31px;
  }
  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include media-to-md {
      gap: 10px;
    }
    @include media-md {
      gap: 20px;
      margin-left: 10px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;

    @include media-to-md {
      gap: 8px;
    }
    @include media-md {
      gap: 7px;
      margin-top: 8px;
    }
  }
  &__title {
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);
  }
  &__name {
    margin: 0;
    padding: 0;
    font-family: $font-accent;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: #fff;

    @include media-to-md {
      font-size: 24px;
      line-height: 23px;
    }
    @include media-md {
      font-size: 20px;
      line-height: 19px;
    }
  }
  &__search {
    display: flex;
    padding: 10px 0 10px 14px;
    width: 340px;
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
      filter: none;
      background-color: rgba(255, 255, 255, 0.05);
    }
    &::before {
      padding-right: 10px;
      font-size: 20px;
      color: #818181;
    }
    &::after {
      @include svg-icon(custom);
      content: "\004e";
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .button-label {
      display: flex;
      justify-content: left;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      text-transform: capitalize;
      color: #818181;
    }
  }
  &__right {
    @include media-md {
      display: none;
    }
  }
}
