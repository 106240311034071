// Sweden login notification
.sweden-login-notification {
  position: relative;
  width: 640px;
  margin-left: auto;

  @include media-sm {
    width: 100%;
    margin-left: 0;
  }

  // Root
  &.notification-root {
    display: block;

    &::before {
      display: none;
    }

    .notification-message {
      padding: 0;
      color: var(--limit-amount-second-color);
      font-size: 14px;
    }
    .notification-action {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  // Col
  &__col {
    padding: 16px 20px 20px;

    @media all and (min-width: 480px) {
      max-width: 50%;
      flex-basis: 50%;
    }

    &-title {
      margin-bottom: 16px;
      color: var(--limit-amount-color);
      font-weight: $font-weight-base;
      text-transform: uppercase;
    }

    &--bets {
      background: var(--limit-amount-background);
    }

    &--limits {
      background: var(--limit-amount-background);
    }
  }

  // Bets
  &__bets {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &-circular-progress {
      @include size(140px);

      flex: 0 0 140px;
    }

    &-stats {
      margin-left: 16px;
    }

    &-stat {
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &--bet,
      &--win {
        position: relative;
        padding-left: 24px;

        &::before {
          @include size(16px);
          position: absolute;
          top: 2px;
          left: 0;
          display: block;
          border-radius: 50%;
          content: "";
        }
      }
      &--bet {
        &::before {
          background: var(--limit-amount-color);
        }
      }
      &--win {
        &::before {
          background: color(primary, main);
        }
      }
      &--total {
        text-align: center;
      }

      &-title {
        font-weight: $font-weight-alt;
        text-transform: uppercase;
      }
      &-amount {
        font-size: 12px;
      }
    }
  }

  // Limits
  &__limit {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 4px;
    }
    &-title {
      font-weight: $font-weight-alt;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
    &-description {
      color: var(--limit-amount-color);
      font-size: 12px;
    }
  }

  // License
  &__license {
    text-align: center;
    background: var(--limit-amount-background);
    border-top: 1px solid #eee;
    padding: 12px;

    a {
      color: var(--limit-amount-link-color) !important;
    }
  }

  // Last login time
  &__last-login-time {
    padding: 12px;
    background: color(primary, main);
    color: color(primary, dark);
    text-align: center;

    &::before {
      @include svg-icon;
      content: '\006d';
      position: relative;
      top: -1px;
      margin-right: 4px;
      font-size: 16px;
      vertical-align: middle;
    }
  }
}
