.standard-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}
.static-table {
  display: table;

  .static-table__payment-provider {
    .pay-and-play & {
      display: none;
    }
  }
  img.static-table__payment-provider-icons {
    max-width: 80px;
    max-height: 30px;
    width: 100%;
    height: 100%;
    margin-right: 16px;
    border: 4px solid #fff;
    border-radius: 4px;
    background: #fff;
  }
  &__nowrap {
    white-space: nowrap;
  }
}

/* Tables */
.table-root {
  .tableHead-root {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .tableBody-root {
    color: color(text, main);
  }
}
.custom-table {
  display: table;
  width: 100%;

  &-head {
    display: table-header-group;

    .custom-table-row {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  &-body {
    display: table-row-group;

    .custom-table-cell {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &-row {
    display: table-row;
  }
  &-cell {
    display: table-cell;
    vertical-align: middle;
  }
}
.table-root,
.custom-table {
  margin-bottom: 20px;
  border: 0;
  border-collapse: collapse;
  overflow-x: auto;

  @include media-sm {
    display: block;
  }
  .table-row--fake {
    display: none !important;
  }
  .table-cell {
    &--rejected-reasons {
      text-align: left;
      padding: 0;
    }
    &--amount {
      font-weight: bold;
    }
    &--status {
      padding: 0;

      span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 3px 3px 17px;
        border-radius: 16px;
        font-family: $font-primary;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #fff;

        @include media-to-md {
          &::before {
            content: "";
            position: absolute;
            left: 10px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
        @include media-md {
          padding: 0;
        }
      }
    }
    &--status--rejected span {
      background-color: color(error);

      &::before {
        background-color: rgba(243, 0, 44, 1);
      }
    }
    &--status--expired span {
      background-color: color(expired);

      &::before {
        background-color: #fff;
      }
    }
    &--status--approved span,
    &--status--login span {
      background-color: color(success);

      &::before {
        background-color: rgba(0, 243, 199, 1);
      }
    }
    &--status--uploaded span {
      background-color: color(disable, dark);

      &::before {
        background-color: #fff;
      }
    }
  }
  th,
  &-head .custom-table-cell {
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    border-bottom: none;
    color: rgba(255, 255, 255, 0.6);

    &:first-child {
      padding-left: 18px;
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      padding-left: 13px;
      border-radius: 0 8px 8px 0;
    }
  }
  th {
    padding: 8px 4px;
  }
  td,
  &-cell {
    padding: 10px 16px;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    border-bottom: none;

    &:last-child {
      padding-right: 4px;
    }
  }
  @media (max-width: 668px) {
    .custom-table {
      display: block;

      &-head {
        display: none;
      }
      &-body {
        display: block;
      }
    }
  }
  .tableRow-root {
    height: initial;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
.static-content {
  .table-root {
    width: 100%;
    border-spacing: 0;
  }
}
.tableHead-Lowercase {
  text-transform: lowercase;
  font-size: 12px;
}
.complaint-procedure__table {
  @include media-sm {
    margin: 0 -20px;
  }
}
.custom-table-cell--amount,
.custom-table-cell--amount-value,
.custom-table-cell--bet,
.custom-table-cell--win {
  white-space: nowrap;
}
