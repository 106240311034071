$height: 48px;
$width: 48px;

.select-locale {
  display: none;
  height: $height;

  .select-icon {
    right: -15px;
  }
  &__item {
    flex: 0 0;
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
  }
  &__flag {
    flex: 0 0;
    max-width: none;
    height: 48px;
    margin-right: 10px;
    border: 1px solid #eee;
  }
  &__lang-name {
    padding: 12px;
    text-transform: uppercase;
    color: #fff;
  }
  & &__lang-name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $height;
    width: $width;
  }
  .select-selectMenu {
    padding: 0;
    border: 0;

    &::after {
      display: none;
    }
    .select-locale__item:hover {
      border-left-color: transparent;
    }
  }
  .field-select {
    & > div {
      &::before {
        display: none;
      }
      &:hover {
        &::before {
          display: none;
        }
      }
    }
  }
  .select-focused {
    border: none;

    &::before,
    &::after {
      display: none;
    }
  }
  ul {
    padding-bottom: 5px;
  }
  &__selector {
    .select-menuPaper {
      margin-left: -5px;
      min-width: auto !important;

      .select-locale__lang-name {
        display: flex;
        justify-content: left;
        width: 100%;
        border-radius: 0;
      }
      ul {
        li {
          padding-inline: 8px;

          &:hover {
            border: none !important;
          }
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
