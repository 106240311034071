.user-identification {
  &__verification--iframe {
    iframe {
      max-width: 900px;
      width: 100%;

      @include media-to-md {
        height: calc(100vh - $header-collapsed-height  - 50px * 2 - 20px * 2);
      }
      @include media-md {
        height: calc(100vh - ($header-collapsed-height + 55px) - 15px * 2);
      }
    }
  }
}
