$deposit-line-style: 1px solid rgba(217, 217, 217, 0.2);

.deposit-form {
  margin-bottom: 0 !important;

  .swiper-slide {
    width: calc(100% / 1.5);
    margin-right: 8px !important;

    @media (max-width: 590px) {
      width: calc(100% / 1.1);
    }
  }

  .campaign-validation-error {
    color: color(errorAccent);
    font-size: 15px;
    font-weight: 400;
  }

  &__title {
    &--method {
      padding: 17px 0 5px 0;
      margin-top: 8px;
      border-top: $deposit-line-style;
    }
  }

  .top-content {
    position: relative;
    background-image: url('/cms/img/deposit-modal-bg.jpg');
    background-position-y: -69px;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-to-md {
      margin: 0 -24px;
      padding: 18px 24px 0 24px;
    }
    @include media-md {
      margin: 0 -16px;
      padding: 18px 16px 0 16px;
    }
    &__title {
      font-size: 20px;
      line-height: 32px;
      text-transform: none;
      font-weight: bold;
      border-bottom: $deposit-line-style;
      padding-bottom: 16px;
    }

    &__current-balance {
      font-weight: bold;

      &__balance {
        position: relative;
        width: 100%;
        border-radius: 24px;
        border: none;
        padding: 11px 45px;
        background: #553c83;
        font-size: 16px;
        margin: 12px 0 18px;

        &::after {
          @include svg-icon(custom);
          content: '\0047';
          position: absolute;
          left: 19px;
          font-size: 18px;
          color: #FEBE10;
        }
      }
    }
  }

  .payment-methods {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: -20px;

    .method-field {
      flex: 1;

      label {
        color: rgba(255, 255, 255, 0.6);
        transform: translate(0, 10px) scale(0.9);
      }
    }
  }

  &__bonuses {
    overflow: hidden;
    padding-right: 24px;

    @include media-to-md {
      margin: 8px -24px 6px 0;
    }
    @include media-md {
      margin: 8px -16px 6px 0;
    }
    .swiper-wrapper {
      position: relative;
      display: flex;
    }

    .bonus-item {
      height: 97px;
      border-radius: 8px;
      font-size: 14px;
      display: flex;
      flex: none;
      flex-shrink: 0;
      align-items: center;
      gap: 16px;
      margin: 0;
      cursor: pointer;
      border: 1px solid #FFFFFF33;
      background: #16161699;

      & > div {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 20px 16px;
      }

      .bonus-item__image {
        max-width: 80px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bonus-item__info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &--title {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.2px;
          text-align: left;
        }

        &--description {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 19.2px;
          text-align: left;
          opacity: 0.6;
        }
      }

      .custom-checkbox {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid  #FEBE10;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .no-bonuses {
      justify-content: center;
      height: 50px;
      font-size: 14px;
      cursor: initial;
    }

    .errorCampaign {
      border: 1px solid color(errorAccent) !important;
    }

    .active {
      border: 1px solid #FEBE10;

      &.bonus-item .custom-checkbox {
        &.checked {
          background-color: #FEBE10;
          border: none;

          &::after {
            content: '';
            position: absolute;
            width: 4px;
            height: 6px;
            border: solid black;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            top: 6px;
            left: 8px;
          }
        }
      }
    }
  }

  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid color(primary, main);

    strong {
      font-size: 20px;
    }
    .exchange {
      display: block;
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }
  &__notifications {
    &:not(:empty) {
      margin-bottom: 20px;
    }

    .notification-root {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }
  &__ukgc-message {
    text-align: justify;
  }
  &__fields-title {
    p {
      margin: 0;
    }
  }
  & &__fields {
    flex: 5;

    .gridItem-root[class*='deposit-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;
    }
    .gridItem-root[class*='deposit-form__field--customer_cc_number']:not(:only-child) {
      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
      }
    }
    label {
      color: rgba(255, 255, 255, 0.6);
    }

    @include media-sm {
      flex: 4;
    }
    @include media-xs {
      flex: 2;
    }
  }
  & &__field {
    &--bank_issuer_id {
      position: relative;
      &:after {
        font-family: "Glyphter-custom";
        content: "N";
        position: absolute;
        top: 40px;
        right: 16px;
        transform: rotate(90deg);
        color: rgba(255, 255, 255, 0.4);
      }
      .field-success label {
        color: rgba(255, 255, 255, 0.6);
        transform: translate(0, 10px) scale(0.9) !important;
      }
    }
  }
  &__amount-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:not(&.field-error) {
      padding-bottom: 12px;
      border-bottom: $deposit-line-style;
    }
    .input-values {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      margin-bottom: 10px;
      order: 2;

      &:empty {
        display: none;
      }
    }
    .input-value button {
      width: 100%;
      padding: 12px 16px;
      border-radius: 8px;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      text-align: center;
      color: rgba(255, 255, 255, 0.4);
      background: rgba(255, 255, 255, 0.1);
      transition: $main-transition-time;

      &:hover {
        color: rgba(22, 22, 22, 1);
        background-color: #FEBE10;
      }
      .currency-symbol {
        font-size: inherit;
      }
    }
    .input-selectedValue button {
      color: rgba(22, 22, 22, 1);
      background-color: #FEBE10;
    }
    .input-values +  .input-underline,
    .input-underline + .button-root,
    .input-underline + .button-wrapper {
      width: 100%;
    }
    .input-values +  .input-underline {
      margin-right: auto;
      &:before,
      &:after {
        content: none;
      }
      input {
        height: 60px;
      }
      @include media-xs {
        margin-bottom: 16px;
      }
    }
    .field-helperError {
      margin-top: 6px;

      p {
        margin: 0;
      }
      .fpLink {
        color: color(errorAccent);
      }
    }
    .deposit-form__accent-link {
      .fpLink {
        font-weight: bold;
        color: #fff;
      }
    }
  }

  &__amount-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-top: 16px;
    border-top: $deposit-line-style;

    p {
      font-weight: normal;
      margin: 0;
      color: rgba(255, 255, 255, 0.6);

      &::before {
        @include svg-icon;
        font-family: "Glyphter-custom";
        content: "p";
        padding-right: 5px;
        vertical-align: text-top;
        font-size: 16px;
      }
    }

    span {
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  &__campaign {
    .field-root label.field-label + div {
      margin: 0;
      color: green;
    }

    .field-helperError {
      margin-top: 4px;
    }
    .select-root .select-selectMenu {
      display: flex;
      align-items: center;
    }
  }
  &__iban {
    margin-bottom: 25px;
    padding-bottom: 25px;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    border-bottom: $deposit-line-style;
    color: rgba(255, 255, 255, 0.6);
  }
  &__button {
    padding: 24px;
    border-top: $deposit-line-style;

    @include media-to-md {
      border-radius: 0 0 10px 10px;
    }

    .button-root {
      width: 100%;
      margin: 0;
    }
  }
  &__bonusTermsCheckbox {
    padding: 0 10px;

    label {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        padding-left: 8px;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .checkbox-default {
      margin: 0;
    }
    .field-root .field-helperError {
      margin: 0;
    }
  }
  &__section-sticky {
    z-index: 2;
    position: sticky;
    right: 0;
    left: 0;
    background: #161616;

    @include media-to-md {
      bottom: 0;
      margin-bottom: 0;
    }
    @include media-md {
      margin-bottom: -16px;
      bottom: -16px;
    }
    .mobile & {
      @include media-to-sm {
        bottom: -16px;
        margin-bottom: -31px;
      }
      @include media-sm {
        margin-bottom: -24px;
        bottom: 0;
      }
    }
  }

  &__declarations-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
  }

  &__declarations-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}
.deposit-form__frame,
.resume-form__frame {
  width: 100%;
  height: 90vh;
  border: none;

  // cubits
  &.deposit-form__frame_provider_410004 {
    margin: 5px 0;
    height: 560px;
  }
  // voucher
  &.deposit-form__frame_provider_150001 {
    display: none;

    & + .deposit-step-back {
      display: none !important;
    }
  }
  // trustly
  &_provider_300001,
  &_provider_300002 {
    height: 600px;

    .tablet &,
    .mobile & {
      background-color: color(primary, light);
    }
  }

  // zimpler
  &_provider_440001 {
    height: 580px;

    .tablet &,
    .mobile & {
      height: 500px;
    }
  }

  &--authentication {
    display: none;

    &_visible {
      height: 100%;
      width: 100%;
      border: none;
      min-height: 400px;
    }
  }
}
.deposit-form__frame {
  display: block;
  height: calc(90vh - 140px);
  @include media-sm {
    min-height: calc(100vh - 155px);
    max-height: calc(100vh - 155px);
  }

  .applied-country--gbr & {
    height: calc(90vh - 190px);
    @include media-sm {
      min-height: calc(100vh - 190px);
      max-height: calc(100vh - 190px);
    }
  }

  &.loading {
    height: 0 !important;
    min-height: 0 !important;
  }
  & + .deposit-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
  &.loading + .deposit-form__circular-progress {
    display: block;
  }
}
.payment-select-field,
.payment-method-field,
.deposit-form__field--bank_issuer_id {
  .select-select {
    display: inline-flex;
    align-items: center;
    width: 100%;

    span {
      display: inline-block;
      color: #FFFFFF;
    }
  }
}
.payment-method-field__img,
img.payment-select-field__img,
.payment-select-field__img {
  max-width: 20px;
  width: 100%;
  max-height: 20px;
  height: 100%;
  background: color(text, main);
  border: 4px solid color(limit-amount, color);
  border-radius: 4px;
  margin-right: 8px;
  object-fit: contain;
}
div.payment-select-field__img {
  margin-right: 5px;
}
.payment-method-field__img-country {
  width: auto;
  max-height: 30px;
  margin-right: 5px;
}
.deposit-summary {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: color(label, disabled);

  &__turnover-title {
    em {
      font-size: inherit;
      font-style: normal;
    }
  }
  &__receive {
    width: 100%;
    padding-bottom: 16px;
    font-size: 14px;

    h4 {
      display: block;
      margin: 0;
      font-size: 16px;
      border: none;
      strong {
        margin-left: 8px;
        font-size: inherit;
      }
    }

    &--title {
      margin-bottom: 8px;
      font-weight: bold;
      color: var(--content-title-color);
    }

    &--info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 16px;
      border-radius: 8px;
      background: #FFFFFF1A;

      p {
        margin: 0;
        padding: 0;
      }
    ;

      .info-item {
        display: flex;
        justify-content: space-between;
      }

      .total-amount {
        padding-top: 10px;
        border-top: $deposit-line-style;
        font-size: 16px;
        line-height: 25.6px;
        color: #FFFFFF;

        &__summary {
          display: flex;
          gap: 5px;
        }
        p {
          font-weight: bold;
        }
      }
    }
  }
  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: color(text, light);

    @include media-xs {
      margin: 10px 0 20px;
    }
    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: color(warning);
    }
  }
  &__notifications {
    display: flex;
    gap: 3px;

    strong {
      font-weight: bold;
    }
  }
  &__notification {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;

    p {
      font-family: $font-primary;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0;
    }
  }
  &__cash {
    p {
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  &__bonus {
    text-align: right;
  }
}
.deposit-limit-confirmation {
  margin-top: 30px;
  text-align: center;
}
.deposit-step-back,
.resume-step-back {
  position: absolute;
  top: 57px;
  left: 22px;
  display: flex;
  min-width: 0;
  min-height: 0;
  padding: 0;
  font-size: 14px;
  border: 0;
  background: none;
  overflow: hidden;
  transition: all 0.2s;
  .button-label {
    transform: translateY(-100%);
    transition: transform .2s;
  }
  &::before {
    @include svg-icon;
    content: '\0062';
    margin-right: 5px;
    font-size: 15px;
    transform: rotate(180deg);
  }
  &:hover {
    color: color(secondary, main);
    background: none;

    .button-label {
      transform: translateY(0);
    }
  }
}
.supported-currency-field .field-helperHint,
.exchange {
  font-size: 12px;
  color: color(input, hint);
}

/** ePro */
.deposit-form--370001 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--customer_cc_name,
      &--customer_cc_surname {
        max-width: 45%;
        flex-basis: 45%;
      }
      &--customer_cc_expmo,
      &--customer_cc_expyr,
      &--customer_cc_cvc {
        max-width: 30%;
        flex-basis: 30%;
      }
    }
  }
}
/* **/

/** Neteller */
.deposit-form--110002 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--netellerAccountId {
        max-width: 65%;
        flex-basis: 65%;
      }
      &--secureId {
        max-width: 25%;
        flex-basis: 25%;
      }
    }
  }
}
/* **/

// Cryptopay
.deposit-form--910002,
.deposit-form--910003 {
  display: flex;
  flex-direction: column;
  flex: none;
  height: auto;

  .field-payAmount--910002,
  .field-address--910002 {
    align-self: flex-end;
    width: 70%;

    @include media-sm {
      order: 2;
      width: 100%;
      margin-top: 0;
    }
  }
  .field-payAmount--910002,
  .field-address--910002,
  .field-address--910003 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrapper {
      display: flex;
    }
    .input-root {
      flex: 1;
    }
    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;
      color: color(secondary, main);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(select, text);
      }
    }
    .button-default::after {
      display: none;
    }
  }
  .field-uri--910002 {
    position: absolute;
    width: inherit;
    margin-top: 155px;

    @include media-sm {
      position: relative;
      order: 1;
      margin-top: 0;
    }
    label {
      display: none;
    }
    .button-primary {
      margin-top: 10px;
    }
  }
  .field-expiresAt--910002 {
    padding-top: 35px;

    @include media-sm {
      order: 3;
      padding-top: 0;
    }
    .expired::before {
      background: color(error);
    }
    label {
      right: 0;
      text-align: center;
    }
    input[type="text"] {
      text-align: center;
    }
  }
}

.deposit-form--910002 {
  .notification-root {
    margin-bottom: 8px;
    animation: none;
  }
}

.field-qrCode--950001 {
  label {
    display: none;
  }
  .button-primary {
    margin-top: 10px;
  }
}

// OXXOPAY, SendAbono
.deposit-form--400002,
.deposit-form--1210001,
.deposit-form--1290001 {
  flex-direction: column;

  .field-reference--400002,
  .field-stpAbonosAccountNumber--1210001,
  .field-reference--1290001 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrapper {
      display: flex;
    }
    .input-root {
      flex: 1;
    }
    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;
      color: color(secondary, main);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(select, text);
      }
    }
    .button-default::after {
      display: none;
    }
  }
}

// Sr.Pago
.deposit-form--1010001 {
  > iframe + .deposit-form__circular-progress {
    display: block;
  }
}

.deposit-form__applePayButton  {
  button {
    width: 156px;
    height: 42px;
    border-radius: 0;
    cursor: pointer;
    margin: 20px 0;
  }
  div[role="progressbar"] {
    margin: 20px 0;
  }
}
