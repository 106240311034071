.netherlands-login-notification {
  $line: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  max-width: 460px;
  margin-left: auto;

  &__container {
    display: flex;
    gap: 10px;
    padding: 25px;
  }
  &__title {
    width: 100%;
    margin: 0;
    padding: 0 0 15px 0;
    border-bottom: $line;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-transform: initial;
    color: #fff;
  }
  &__subTitle {
    display: flex;
    align-items: center;
    gap: 9px;
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 22.4px;
    color: #fff;
  }
  &__icon {
    &::before {
      padding: 2px;
      font-size: 20px;
      opacity: 40%;
    }
  }
  &__forms {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 14px;
    height: 100%;
    padding-top: 5px;
    overflow-y: auto;

    @include media-to-sm {
      max-height: calc(100vh - 300px);
    }
    @include media-sm {
      max-height: calc(100vh - 370px);
    }
  }
  &__limits {
    display: flex;
    flex-direction: column;
    flex: auto;
    gap: 7px;
  }
  &__limit {
    position: relative;
    display: grid;
    padding: 12px 15px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);

    @include media-to-sm {
      grid-template-columns: 0.9fr 1fr 1fr;
    }
    @include media-sm {
      grid-template-columns: 1fr 1fr;
    }

    &__title {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      color: rgba(255, 255, 255, 0.6);

      @include media-sm {
        grid-column: 1 / -1
      }
    }
    & &__description {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      color: #fff;

      &__value {
        font-weight: 600;
      }
    }
  }
  &__max-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0 16px;
    border-bottom: $line;

    &__title {
      margin: 0;
      padding: 0;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      color: #fff;
    }
    & &__description {
      margin: 0;
      padding: 0;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 22.4px;
      color: #fff;
    }
  }

  &--nld-limit {
    .netherlands-login-notification--col {
      padding: 16px 20px 28px;
    }

    .netherlands-login-notification__limit {
      &-title-not-reach{
        color: color(accent, main);
      }
    }
  }

  &__CTA {
    &__button {
      width: 100%;
    }
    .netherlands-login-notification__limit {
      border: 1px solid rgba(254, 190, 16, 1);

      &__title,
      &__description {
        color: rgba(254, 190, 16, 1);
      }
    }
  }
  & &__NDT {
    @include media-sm {
      .netherlands-login-notification {
        &__limit {
          grid-template-rows: 1fr;

          &__title {
            grid-column: 1;

            + .netherlands-login-notification__limit__description {
              display: none;
            }
          }
        }
      }
    }
    &__description {
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      line-height: 19.2px;
      color: #fff;
    }
  }
  &__close-button {
    width: 100%;
    margin-top: 15px;
  }
  &__last-login-time {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    color: rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.05);

    &::before {
      padding: 0 6px 0 0;
      font-size: 14px;
      opacity: 40%;
    }
  }
  // Root
  &.notification-root {
    display: block;

    &::before {
      display: none;
    }
    .notification-message {
      padding: 0;
      color: var(--limit-amount-second-color);
      font-size: 14px;
    }
    .notification-action {
      position: absolute;
      top: 16px;
      right: 9px;

      button {
        background-color: rgba(46, 46, 46, 0.7);
      }
    }
  }
}
