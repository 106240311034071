.notification-root {
  flex-wrap: nowrap;
  flex: 0 0 auto;
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px !important;
  padding: 0;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.11);
  background: var(--notification-background);
  text-align: left;

  &::before {
    @include svg-icon;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 12px;
    border-radius: 8px 0 0 8px;
    font-size: 19px;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }

  p {
    margin: 0;

    @include media-to-sm {
      font-size: 12px;
    }
    @include media-sm {
      font-size: 9px;
    }
  }

  ol {
    padding-left: 13px;
  }

  .notification-message {
    font-size: 12px;
    color: var(--notification-color);

    @include media-to-sm {
      padding: 15px 20px;
    }
    @include media-sm {
      padding: 10px;
    }
  }

  .notification-action {
    margin-right: 0;
    padding-left: 0;
    color: color(text, main);
    align-self: baseline;

    button {
      @include close-button;
      background: none;
    }
  }
}
.notification-error {
  border-color: color(error);

  &::before {
    content: '\0042';
    display: flex;
    align-items: center;
    color: #ff002e;
    background-color: color(error);
  }
}
.notification-success {
  border-color: color(success);

  &::before {
    content: '\0043';
    display: flex;
    align-items: center;
    color: #00f3c7;
    background-color: color(success);
  }
}
.notification-warning {
  border-color: color(warning);

  &::before {
    @include svg-icon(custom);
    content: '\0037';
    display: flex;
    align-items: center;
    color: rgba(254, 190, 16, 1);
    background-color: color(warning);
  }
}
.notification-info {
  &::before {
    @include svg-icon(custom);
    content: '\0070';
    display: flex;
    align-items: center;
  }
}
.notifications {
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 99998;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 460px;
  pointer-events: none;

  @include media-md {
    width: 60%;
  }
  @include media-sm {
    right: 10px;
    left: 10px;
    width: auto;
  }
  .notification {
    display: flex;
    align-items: flex-end;
    min-width: 100%;
    margin-top: 5px;
    padding-bottom: 10px;
    overflow: hidden;
    pointer-events: all;

    &:last-child {
      padding-bottom: 0;
    }
    &-root {
      margin-bottom: 0;
    }
    ul {
      padding-left: 10px;
    }
    .notification-action {
      .material-icons {
        font-size: 0;
      }
    }
  }
}
.notification-root {
  &.cookiesPolicyAccepted {
    &::before {
      display: none;
    }
  }
}
