.verification-in-iframe {
  &--method-idenfy {
    text-align: center;
  }
  &__waiting-text {
    margin-top: 20px;
  }
  &__iframe {
    width: 100%;
    min-height: 70vh;
  }
}
