.push-notifications-profile {
  &__checkbox {
    max-width: 100%;
    flex-basis: 100%;
  }

  &__btn {
    color: color(secondary, main);
    cursor: pointer;
    border: none;
    background: none;
  }

  &__modal-subtitle {
    text-transform: uppercase;
    margin: 36px auto 24px;
  }

  &__modal-device {
    display: flex !important;
    justify-content: space-between;
    margin: 8px 0;
  }

  &__modal-current-label {
    color: color(text, light);
  }

  &__unsubscribe {
    color: color(error);
    cursor: pointer;
    border: none;
    background: none;
    text-decoration: underline;
  }
}
