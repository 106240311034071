.promotions {
  &__card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
    grid-gap: 24px;
  }
  &__forfeit {
    padding: 0 15px;
    margin: 40px 0;

    @include media-sm {
      margin: 20px 0;
    }
  }
}
