$responsible-widget-single-row-height: 32px;
$responsible-widget-multi-rows-height: 52px;

@mixin element-styles-accounting-responsible-widget($property, $value: 0, $important: null) {
  @if type-of($value) == 'list' {
    #{$property}: ($responsible-widget-single-row-height + nth($value, 1)) $important;

    @include media-sm {
      #{$property}: ($responsible-widget-multi-rows-height + nth($value, 2)) $important;
    }
  } @else {
    #{$property}: ($responsible-widget-single-row-height + $value) $important;

    @include media-sm {
      #{$property}: ($responsible-widget-multi-rows-height + $value) $important;
    }
  }
}

.responsible-widget {
  position: fixed;
  z-index: 1501; // 1500 is z-index of left menu
  top: 0;
  right: 0;
  left: 0;
  background: var(--content-background);
  border-bottom: 1px solid lighten(color(primary, dark), 60%);

  &__container {
    @include element-styles-accounting-responsible-widget('height');

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;

    @include media-sm {
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &__time {
    margin-right: 30px;

    @include media-sm {
      margin: 0 10px;
    }
  }

  &__buttons {
    margin-left: auto;

    @include media-sm {
      width: 100%;
      margin-top: 4px;
      margin-left: 0;
    }
  }

  .sweden-responsible-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @include media-sm {
      justify-content: center;
    }

    &__button {
      margin: 0;
      line-height: 22px;

      &:not(:last-child) {
        margin-right: 2px;
      }

      @include media-xs {
        font-size: 2.5vw;
      }
    }
  }

  .session-time {
    &__title {
      display: inline;
      margin: 0 5px 0 0;
      font-size: 14px;
      color: lighten(color(primary, dark), 30%);

      &::after {
        content: ':';
      }
    }

    &__icon {
      display: none;
    }
  }

  + .main-decorator {
    .main-decorator__content {
      margin-top: $responsible-widget-single-row-height + $header-expanded-height;

      @include media-md {
        margin-top: $responsible-widget-single-row-height + $header-collapsed-height;
      }

      @include media-sm {
        margin-top: $responsible-widget-multi-rows-height + $header-collapsed-height;
      }

      .authorization-required-page {
        min-height: calc(100vh - #{$header-expanded-height} - 40px - #{$responsible-widget-single-row-height});

        @include media-md {
          min-height: calc(100vh - #{$header-collapsed-height} - 40px - #{$responsible-widget-single-row-height});
        }

        @include media-sm {
          min-height: calc(100vh - #{$header-collapsed-height} - 40px - #{$responsible-widget-multi-rows-height});
        }
      }
    }

    // Bingo
    .bingo-frame {
      height: calc(100vh - #{$responsible-widget-single-row-height} - #{$header-expanded-height});

      @include media-md {
        height: calc(100vh - #{$responsible-widget-single-row-height} - #{$header-collapsed-height});
      }

      @include media-sm {
        height: calc(100vh - #{$responsible-widget-multi-rows-height} - #{$header-collapsed-height});
      }
    }

    // Header
    .header {
      @include element-styles-accounting-responsible-widget('top');
    }

    // Home menu
    .home-menu__wrapper {
      top: $responsible-widget-single-row-height + $header-collapsed-height;

      @include media-md {
        top: auto;
      }
    }
  }

  // Decorators
  + .popup-decorator,
  + .game-decorator {
    @include element-styles-accounting-responsible-widget('padding-top');
  }

  + .popup-decorator {
    .popup-decorator__close-button {
      @include element-styles-accounting-responsible-widget('top', 20px);
    }
  }
}

.applied-country--swe {
  // #root
  @include media-md {
    [data-url$="/real"],
    [data-url$="/demo"] {
      .header {
        display: none;
      }
    }
  }

  // Aside menu
  .aside-menu {
    @include element-styles-accounting-responsible-widget('padding-top', 0);
  }

  // Select locale
  .select-locale__selector {
    .select-menuPaper {
      @include element-styles-accounting-responsible-widget('top', 16px, !important); // 16px comes from calculated inline style
    }
  }

  // Modal
  .modal-root {
    @include element-styles-accounting-responsible-widget('padding-top');
  }

  // Notifications
  .notifications {
    @include element-styles-accounting-responsible-widget('top', 5px);
  }

  // Resume step back
  .resume-step-back {
    @include element-styles-accounting-responsible-widget('top', 20px);
  }

  // Static page menu
  .static-page-menu__item {
    @include element-styles-accounting-responsible-widget('top', ($header-collapsed-height + 20px), !important);
  }

  // Post login menu
  .post-login-menu {
    @include element-styles-accounting-responsible-widget('top', $header-collapsed-height, !important);
  }
}
