.game-provider-license {
  &.game-provider-license--uk { // Increasing specifity
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include media-sm {
      text-align: left;
    }

    img {
      @include size(32px);
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 8px;
      filter: brightness(0) grayscale(100%);
    }

    p {
      color: color(text, light);
    }
  }
}
