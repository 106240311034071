.verification-form {
  text-align: center;
  padding: 20px;

  & > div:first-child {
    margin-top: 0;
  }

  &__text,
  &__send-code,
  &__edit-field,
  &__send-code-description,
  &__description,
  &__type,
  &__field-value {
    max-width: 510px;
    margin: auto;
  }
  &__type {
    margin: 0 auto 20px;
  }
  &__static-field {
    margin: 20px auto;
  }
  &__footer {
    padding: 20px;
    margin: 40px -20px -20px;
  }
  &__field-title {
    margin-top: 25px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  &__field-value {
    margin-top: 10px;
    padding: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    border: 1px dashed #fc0;
    font-size: 16px;
    text-align: center;
  }
  &__attempts {
    margin: 10px;
  }
  &__edit-field {
    width: 100%;
    margin-bottom: 10px;
  }
  .button-root {
    margin: 0 10px;
  }
  &__send-code {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & .field-input {
      width: auto;
      flex: 1;
    }
    & .field-label {
      width: 100%;
      text-align: left;
    }
    & .button-root {
      margin: 0 0 0 10%;
    }
  }

  div:not(.modal-contentRoot) > & {
    padding: 20px;
  }
}
