/* fixed the problem with the height of methods in the modal deposit */
.deposit-modal {
  &--frame {
    .modalContentWrapper {
      max-height: 90vh;

      @include media-sm {
        height: 100vh;
        max-height: none;
      }
    }
    .modal-contentRoot {
      height: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &--shuftipro {
    .modal-paper {
      width: 100%;
      max-width: 870px;
    }
  }
  .modal-paper .modalContentWrapper {
    .modal-titleRoot {
      padding-bottom: 0;
      background-image: url('/cms/img/deposit-modal-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      @include media-md {
        padding-inline: 16px;
      }

      h2 {
        padding-bottom: 14px;
      }
    }
  }
}
