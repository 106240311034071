.help-page-container {
  @include media-to-sm {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @include media-sm {
    padding: 20px;
  }
}
.list-of-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin: 0;

  &__icon {
    align-self: center;
    justify-self: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
  &__link {
    position: relative;
    display: flex;
    gap: 9px;
    padding: 24px;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: color .3s ease-in-out;

    &:last-of-type {
      border-bottom: none;
    }
  }
  &__name {
    align-self: center;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
  }
}

.help-page {
  &__breadcrumbs {
    margin-bottom: 22px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.45px;
    line-height: 18px;
  }
  &__link {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    transition: color .2s ease-in-out;

    &:not(:last-child) {
      &::after {
        @include svg-icon(custom);
        content: "\004e";
        position: relative;
        padding-right: 12px;
        padding-left: 12px;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    &_with-border {
      font-weight: 600;
      color: #fff;
      transition: color .2s ease-in-out, border-left .2s ease-in-out;
    }
  }
  &__title {
    font-family: $font-accent;
    font-size: 24px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    @include media-to-md {
      margin: 25px 0;
    }
    @include media-md {
      margin: 25px 0 15px;
    }
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.section-answer {
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);

  ul {
    padding-left: 19px;
  }
  &[open] &__body{
    padding: 0 16px 16px 16px;
    animation: animateDown 0.3s ease forwards;

    p {
      margin: 0 0 10px;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0;
      color: rgba(255, 255, 255, 0.6);
    }
  }
  &_border {
    border-top: 1px solid color(secondary, main);
    margin: 0;
    padding: 14px 0;
  }
  &__title {
    position: relative;
    padding: 16px 48px 16px 16px;
    font-weight: bold;
    list-style-type: none;
    cursor: pointer;

    &::after {
      @include svg-icon(custom);
      content: "\004e";
      transition: $main-transition-time;
    }
    &::-webkit-details-marker {
      display: none;
    }
    &:focus {
      outline: none;
    }

    &_with-icon::after {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%) rotate(90deg);
      transition: transform ease $main-transition-time;
      color: rgba(255, 255, 255, 0.6);
    }
  }
  &[open] &__title_with-icon::after{
    transform: translateY(-50%) rotate(270deg);
  }
  &__body {
    padding: 20px 0 0 0;
    ol {
      padding-left: 19px;
    }
  }
  &__help {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    margin: 15px 0;
    color: color(primary, main);
  }
  &__button {
    display: block;
    width: 110px;
    text-align: center;
    padding: 7px 0;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
  }
  &__list {
    margin: 10px 0;
  }
  &__element-of-list {
    margin: 10px 0;
  }
}
.help-page > .section-answer__body {
  padding-top: 0;
}
@keyframes animateDown {
  0% {
    opacity: 0;
    transform: translatey(-15px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@media screen and (max-width: 768px) {
  .help-page{
    &__breadcrumbs {
      font-size: 14px;
    }
    &__link {
      padding: 0 0 0 10px;
    }
    &__breadcrumbs {
      line-height: 22px;
    }
  }
  .help-page__info {
    max-width: 250px;
    min-width: 250px;
  }
  .list-of-header__link::before {
    font-size: 36px;
  }
  .list-of-header__name,
  .general-info {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px){
  .help-page {
    &__info {
      display: flex;
      flex-direction: column-reverse;
      max-width: initial;
    }
    &__link {
      padding: 0 5px;
    }
  }
  .popular-question {
    margin: 18px 0;
    padding: 0 25px;
  }
  .general-info {
    padding: 0 25px 17px 25px;
  }
}
