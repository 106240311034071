.verification {
  .radioGroup-root {
    flex-direction: column;

    label + label {
      span {
        margin-top: 10px;
      }
    }
  }
}
